.container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    padding: 3vh 0;
    background-color:    #fdebf0 !important;
}

.middleDiv {
    height: auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 1.4rem;
    padding: 3vh 0; 
}

.middleDiv > .specialP{

    font-size: 38px;
    
    color: #444;
    font-weight: 200;

}

.middleDiv > p {
    font-size: 20px;
    color: #777;
    font-weight: 200;
    
}

.italics {
    font-style: italic;

}

  
.hr {
    width: 30%;
 
    
}

.hr  > hr{
    align-self: left;
    margin-bottom: 2vh;
}

@media only screen and (max-width: 1000px) {

.middleDiv > .specialP{

    font-size: 27px;
  

}

}

@media only screen and (max-width: 600px) {

    .picDiv {

        height: 270px;
        width:100%;
    
    }

    .contactFormWrapper {
        width:  70%;
     
        padding: 0.6vh 0.5vw;
       }

       .getBtn {
       
        padding: 0.4vh 0.4vw;
       
        font-size: 14px;
       
    }

    .contactFormWrapper button {
        margin-top: 0.2rem;
    }

   
    

       .contactFormWrapper >  p {
        font-size:13px
       }

       .contactFormWrapper> .specialP {
        font-size: 24px;
        margin-bottom: 0.2rem;
    }

    .middleDiv > .specialP{

        font-size: 23px;
      
    
    }
    
    .middleDiv > p {
        font-size: 16px;
    
        
    }


 
      

   


}