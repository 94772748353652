.container {
    width: 100%;
    height: 100%;
  
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
}


@media only screen and (max-width: 850px) {

 

    
.container {

    height: 50vh !important;

}
  

}