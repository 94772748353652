
.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color:    #fdebf0 !important;


}


.imgWrapper {
   
    width: 100%;
    height: 90vh;
   position: relative;
    background-color: #222;

}

.imgWrapper > img {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    object-fit: cover;

}

.abso {
    position: absolute;
   top: 40%;
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
  
}


.abso > p {
   text-align: center;
    font-size: 70px;
  
    color: #fff;
    width: 100%;
}



.middleDiv {
    height: auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 1.3rem;
    padding: 4vh 0; 
}

.middleDiv > .specialP{

    font-size: 36px;
   
    color: #444;
    font-weight: 200;


}

.middleDiv > p {
    font-size: 20px;
    color: #444;
    font-weight: 200;
    
}

.giftWrapper {
    display: flex;
    width: 90%;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 4vh;
    
}




.gift {
    width: 30%;
    height: 20%;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
   margin-bottom: 5vh;
   opacity: 0;
    transform: translateY(15%);
    transition: all 0.6s ease-in-out;
}

.gift.appear {
    opacity: 1;
    transform: translateY(0);
    
    
  }

.imgDiv {
    height: 300px;
    width: 100%;
}

.imgDiv > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.textWrap {
    background-color: #fff7f9;
    padding: 30px;
}


.textWrap > .specialP {
    font-size: 30px !important;
    width: 70%;
    color: #444;
    text-align: left;
    padding-bottom: 1vh;
    font-weight: 200;
    

}
.textWrap > P {
    font-size: 17px;
color: #444;
font-weight: 200;
}

.hr {
    width: 44%;
 
    
}

.hr  > hr{
    align-self: left;
    margin-bottom: 1.6vh;

}

.extraDiv {
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

}

.extraDiv > .specialP {
    text-align: center;
    font-size: 23px;
    color: #444;
    font-weight: 200;
}

.extraDiv> a  {
    text-decoration: none;
}



.extraDiv> a > button {
    background-color: #2d2c2c !important;
    
   text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 60px;
    display: flex;
    font-size: 18px;
    
    cursor: pointer;
    font-weight: 200;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #fff!important;
}


.extraDiv> a > button:hover  {
    background-color: #fff !important;
    color: #444 !important;

   
   

}



@media only screen and (max-height: 700px) {

    .abso > p {
        font-size: 35px;
     
        color: #fff;
    }

    
    .abso {
     
        top: 30%;

    }

}

@media only screen and (max-width: 760px) {

   

    .abso {

        width: 220px;
        height: 250px;
   
     
    
        }


        .abso > p{
            font-size: 35px;
         
            color: #fff;
        }


}

@media (max-width: 900px) {

    .icons {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     }


     .row {
        flex-direction: column;
     
     
       }

 

     .giftWrapper {
        flex-direction: column;
        
        }

        .gift {
            width: 100%;
        }
        
  


}

@media (max-width: 600px) {
 
    .abso > p {
    
        font-size: 28px;
      
        
        width: 80%;
    }


    .extraDiv > .specialP {
   
    font-size: 16px;
    width: 80%;
 
}

.extraDiv> a > button {
    height: 40px;
    font-size: 13px;
}


    
.middleDiv > .specialP{

    font-size: 25px;
   
    color: #444;

}

.middleDiv > p {
    font-size: 16px;
    color: #444;
    
}


.textWrap > .specialP {
    font-size: 26px !important;
  

}
.textWrap > P {
    font-size: 15.5px;
color: #444;
}


 






}