.container {
  width: 100%;
  height: auto;
  padding: 3vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:    #fdebf0 !important;
}


.topWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  padding-bottom: 3vh;
}


.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
  justify-content: center;
  font-size: 16px;
  gap: 1vh;
  font-weight: 200;
}


.link:hover {
  color: goldenrod;
}
.topWrapper  > h2 {

  font-weight: 200;
  width: 80%;
  font-size: 30px;
  text-align: center;
  

}

.wrapper {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
  gap: 2rem;
  flex-wrap: wrap;
}

.imgCont {
width: 400px;
  height: auto;
  opacity: 0;
  margin-bottom: 1vh;
  overflow: hidden;
  transform: translateY(15%);
  transition: all 1s ease-in-out;
}



.giftBtn {
  background-color: #2d2c2c !important;
  font-weight: 200;
  
 text-decoration: none;
  padding: 0.4vh 0.4vw;
  height: 55px;
  display: flex;
  font-size: 15px;
  width: 130px;
  
      cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-in-out;
  color: #fff!important;
}



.giftBtn:hover {
  background-color: #fff !important;
  color: #222 !important;
}



.imgCont.appear {
  opacity: 1;
  transform: translateY(0);
}

.imgCont > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .imgCont {
      width: 22%;
  }

}


@media (max-width: 1200px) {

  .wrapper {
      flex-direction: column;
  }

  .imgCont {
      width: 95%;
      height: auto;
  }

}



@media screen and (max-width: 1192px) and (max-height: 822px) {


  .imgCont {
      height: 600px;
  }


 

 
  
  
}


@media (max-width: 600px) {


  .container {
      padding: 2.2vh 0;
  }

  
  .imgCont {
      width: 95%;
      height: auto;
  }

  .wrapper {
      padding: 1rem 0;
      gap: 2rem;
  }

}

@media (max-width: 350px) {

  
  .imgCont {
      height: auto;
  }

}


