.container {
    height: auto;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background-size: cover;
    padding-bottom: 8vh;
    padding-top: 3vh;
    background-color: #fdebf0 !important;
    position: relative;
    z-index: 99;

}

.newCont {
    width: 85%;
    height: auto;
    padding: 1.5vh 0;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 1.5rem;
    padding-bottom: 1vh;
   
    transition: all 0.6s ease-in-out;
}

.newCont.appear {
    opacity: 1;
    transform: translateY(0);


}

.newCont>.specialP {
    font-size: 38px;

    width: 90%;

    text-align: left;
    color: #444;
    font-weight: 200;

}

.newCont>p {
    font-size: 20px;
    width: 90%;
    text-align: left;
    color: #444;

    font-weight: 200;
}

.wrapper {
    width: auto;
    height: auto;
   
  
    display: flex;
    background-color: transparent !important;
    flex-direction: column;
    justify-content: center;
    padding: 5vh 0.5vw;
    align-items: center;
    gap: 2rem;
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.6s ease-in-out;
}

.wrapper>h3 {
    font-size: 40px;
    text-align: center;
    width: 70%;
    font-weight: 100;
    text-transform: uppercase;
    color: #fff;

}

.wrapper>p {
    width: 70%;
    font-size: 22px;
    text-align: center;
    font-weight: 200;
    color: #fff;

}

.wrapper>.getBtn {
    background-color: #f3c7c7 !important;

    text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 6vh;
    display: flex;
    font-size: 18px;
    width: 120px;
    font-weight: 200;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #444 !important;
}







.wrapper>.getBtn:hover {
    background-color: #fff !important;
    color: #222 !important;




}


.wrapper.appear {
    opacity: 1;
    transform: translateY(0);


}

.hr {
    width: 30%;


}

.hr>hr {
    align-self: left;
    margin-bottom: 1.6vh;

}


@media (max-width: 900px) {

    .newCont>.specialP {

        font-size: 27px;


    }

    .wrapper {
        width: 80%;
    }

}

@media (max-width: 600px) {

    .container {
        height: auto;
    }



    .newCont>.specialP {
        font-size: 23px;



    }

    .newCont>p {
        font-size: 16px;

    }

    .wrapper>h3 {
        font-size: 27px;
        text-align: center;
        text-transform: uppercase;
        width: 70%;
    }

    .wrapper>p {
        width: 80%;
        font-size: 20px;
        text-align: center;
        font-weight: 200;
    }

    .wrapper>p>a {
        font-size: 24px;
    }


}