.container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    background-color:  #fdebf0 !important; 
    padding-bottom: 6vh; 
  
}

.wrapper {
    height: 98%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 2rem;
    justify-content: center;
    padding-top: 4vh;
  
}

.wrapper >  .specialP {
    font-size: 38px !important;
    color: #444 !important;
    font-weight: 200;
}

.wrapper > p {
    font-size: 18px;
    text-align: left;
    color: #444;
    font-weight: 200;
}

.icons {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.icons > span {
    font-size: 17px;
    color: #444;
    font-weight: 200;
}

.row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    

    gap: 2rem;

}

.box {
    display: flex;
    height: 150px;
    align-items: left; 
    flex-direction: column;
    justify-content: flex-start;
   

    width: 25%;
    height: 200px;
    gap: 1rem;
}

.box > p > a {
    text-decoration: none;

}

.box > p {
    font-size: 17px;
    color: #444;
    font-weight: 200;
}

.box > .specialP {
    font-size: 25px !important;
    color: #444 !important;
    font-weight: 200;
}
.box > h4 {
    font-size: 25px;
}

.parkingDetails {
    font-size: 18px !important;
}
.hr {
    width: 30%;
}

.hr  > hr{
    margin-bottom: 1vh;

}






@media (max-width: 900px) {

    .icons {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     }


     .row {
        flex-direction: column;
     
     
       }
       .icons > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

       }



}

@media (max-width: 600px) {
 
  .wrapper > .specialP {
      font-size: 25px !important;
  
     
      
  }
  
  .wrapper > p {
      font-size: 16px;
      
  }

 


  .icons > span {
    font-size: 14px;
    color: #444;
}





}