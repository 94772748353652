.container {
    height: auto;
    width: 100%;
    display: flex;
    gap: 1vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff7f9;
}

.container > h1 {
    text-align: center;
    font-size: 50px;
    padding-top: 2vh;
    width: 95%;
    font-weight: 200;
    margin-bottom: 2vh;

}

.container > h2 {
    text-align: center;
    font-size: 36px;
}
.properties{
    margin-top: 1rem;
    
    margin-bottom: 3rem;
    width: 90%;
    display: flex;
  align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    
}

form > p {
  font-size: 18px !important;
}

.property {
    height: auto;
    background-color: #faf9f6;
    border-radius: 20px !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    width: 345px;
    border: 1px grey solid;
    overflow: hidden;
}


.property>.imgContainer {
    display: inline-block;
    max-height: 225px;
    width: 100%;
    min-height: 225px;
    
    
    
    
  
    overflow: hidden;
    cursor: pointer;
}

.property>.imgContainer>img {
    height: 225px;
    width: 100%;
    object-fit: cover;
    transition: 150ms all;
    overflow: hidden;
}

.property > .details {
  height: auto;
  padding: 2vh 0;
  gap: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.property > .details > h3 {

  font-size: 27px;
  text-align: center;


}

.property > .details > p {
  text-align: center;
  width: 80%;


}


/* list property */
.listPropertyForm {
  

    
    width: 100%;
    

  
  
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
   
    padding-bottom: 6vh;
  
  }
  
  .listPropertyWrapper {
    border-radius: 20px;
    background-color: #2d2c2c !important;
   
  font-size: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    width: 40%;
   
    padding: 2vh 0;
    
  }


  .listPropertyWrapper > form > .inputs {
    gap: 3rem;
    width: 80%;
  }

  .listPropertyWrapper > form > p {
    color: #fff !important;
    text-align: center;
  }

  .listPropertyWrapper > form > input {
    font-size: 18px;
    width: 80%;
  }

  .listPropertyWrapper > form > .inputs > input{
    font-size: 20px;


  }
  
  
    
  .listPropertyWrapper>h2 {
    text-align: center;
 
   color: #fff;
    font-size: 35px;
    margin: 2vh 0;
  }
  
  .listPropertyWrapper > form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .listPropertyWrapper > form  > .inputs > textarea{
    width: 100%;
    height: 200px;
    font-size: 18px;
  }
  
  form > .inputs {
    display: flex;
    flex-direction: column;
   
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
  }

  .garden {
    color: #fff;
    width: 100%;
  }

  .garden > label {
    width: 100%;
    display: flex;
    font-size: 22px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
  }

  .garden > label > .datetime {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 22px;
    width: 100%;

  }

  


 


  



  .rdt .rdtOpen  > .rdtPicker > .rdtTime > table > tbody > tr > td > .rdtCounters {
    display: flex;
    flex-direction: row;
  }
  

  
  .listPropertyWrapper>form>div>label {
    display: flex;
   
    align-items: center;
    gap: 10px;
    color: #fff !important;
    font-size: 20px;
  
  }
  
  
  

  .formButton {
   
    color: #222;
    width: 70px;
    margin: 2vh 0;
    
    background: transparent;
   
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  
 
  
  .formButton2 {
   
    color: #fff;
    width: 90px;
    margin: 0.2vh 0;
    background: transparent;
    border: 2px #fff solid;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    font-weight: 300;
  }
  
  
  .formButton2:hover {
    color: green;
    border: 2px green solid;
  }
  
  .formButton:hover {
    color: green;
    border: 2px green solid;
  }
  
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 11px;
  }
  
  .listPropertyWrapper>form>p  {
    margin-top: 1vh;
    margin-bottom: 1vh;
    color: #222;
    font-weight: bold;
  }
  
  
  
  
  

  

  
  
  .newPass {
    margin: 2vh 0;
  }
  
  .descrip {
    height: 130px;
    width: 100%;
    font-size: 20px;
    margin: 0.5vh 0;
  
      overflow: auto;
      outline: none;
  
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
  }
  
  
  .userImg {
    object-fit: cover;
  }

  .btnDels {

    padding-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .btnDelete, .btnEdit, .btnProfile {

    

    width: 50%;
    margin: 0.2vh 0;
    background: transparent;
    border: 2px #222 solid;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;

  }

  .btnProfile {
    margin-top: 5vh;
    width: 100px;
    height: 60px;
    font-size: 20px;
  }


   .btnProfile:hover {
    
      color: rgb(30, 147, 30);
      border: 2px rgb(30, 147, 30) solid;
  
    



  }


  .btnDelete:hover {

    color: red;
    border: 2px red solid;

  }
  
  

   .btnEdit:hover  {
    color: rgb(255, 221, 0);
    border: 2px rgb(255, 221, 0) solid;

   }
  .btnUpload {
    margin: 0.5vh 0;
    
   text-align: center;
   padding: 0 1vw;
    color:  #fff;
    display: flex;
    cursor: pointer;
   font-size: 20px;
   border: 2px #fff solid;
    
    
  } 
  
  .btnUpload2 {
    color:  #222;
    display: flex;
    width: 100%;
    margin: 0.5vh 0;
    cursor: pointer;
    border: 2px #222 solid;
   
    
    
    
  }
  
  .btnUpload2:hover {
    color: green;
    
  }
  
  .btnUpload:hover {
    color: green;
    border: 2px green solid;
  }
  
  
 
  
  .listPropertyWrapper button {
    margin-top: 0.5rem;
  }
  
  .upload {
    color: #222;
    margin: 0.2vh  0.2vw;
    font-weight: bold;
    border: 2px #fff solid;
    cursor: pointer;
    display: flex;
   
  }
  
  .upload:hover {
    color: green;
    
    cursor: pointer;
  }
  
  .removeIcon {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    font-size: 22px;
    cursor: pointer;
  }
  
  .error {
    padding: 1rem;
    background-color: #f00;
    color: #fff;
    position: absolute;
    z-index: 9999999999;
    right: 1rem;
    top: 3rem;
    border-radius: 8px;
  }
  
  /* user modal */
  .username{
    cursor: pointer;
  }
  
  .userModal{
    position: absolute;
    padding: 0.5rem 1rem;
    background-color: #efefef;
    color: #222;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    top: 2.5rem;
    right: 9rem;
    border-radius: 20px;
  }
  
  .userModal > .myProperties{
    cursor: pointer;
  }
  
  .userModalClose{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
  }
  
  
  
  .myProfile{
    color: inherit;
  }
  
  .type {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .radio {
    display: flex;
    
    align-items: left;
  }
  
  .radio > label {
    margin-right: 1vw;
  }

  /* RESPONSIVE */@media only screen and (max-width: 968px) {

    .bottomWrapper > h2{

        text-align: center;

    }

    

    .wrapper {
        flex-direction: column;
        border:0;
        background: #fff;
        box-shadow: none; 
        padding: 0.2vh 0.2vw;
        

    }



   .wrapper > .options {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;
        box-shadow: none; 
        background: #fff;
        

    }

      .listPropertyWrapper {
    width: 70%;
  }

    
}



@media only screen and (max-width: 792px) {



  

    .properties{
       flex-direction: column;
       align-items: center;
       
       
       
    }

    .property {
        width: 50%;
        height: 100%;
        
    }


    .property>.details> p {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
    }

    .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 250px;
        font-size: 11px;
        color: grey;
    }
    
    .property>.imgContainer {

        max-height: 200px;
        width: 100%;
        min-height: 200px;
    }

    .property>.details>.propName {
        
        font-size: 13px;    


    }
    .price {
        display: flex;
        font-size: 11px;
    }

}


@media only screen and (max-width: 492px) {

  .listPropertyWrapper {
    width: 80%;
  }


    .property {
        width: 85%;
        height: 100%;
    }

    .moreDetails {

        font-size: 10px;



    }


    .listPropertyWrapper > form > .inputs > input{
      font-size: 17px;
      width: 85%;
  
    }

    .listPropertyWrapper > form > .inputs > textarea{

      font-size: 16px;
      width: 90%;

    }

}