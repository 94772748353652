.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5vh 0;
     gap: 5rem;

}

.btnDels {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}




.makeWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3c7c7 !important;
    width: auto;
    min-width: 70%;
    max-width: 85%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 20px;
    padding: 2vh 2vw;
    transition: all 0.4s ease-in-out;
}


.makeWrapper>h2 {
    font-weight: 200;
    font-size: 35px;
    padding-bottom: 3vh;
    text-align: center;
}


.titleInput {
    margin: 2vh 0;
    font-size: 27px;
    padding: 1vh 1vw;
    width: 90%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}


.titleInput2 {
    margin: 2vh 0;
    font-size: 20px;
    padding: 1vh 1vw;
    width: 90%;
    margin-bottom: 3vh;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 999;
}



.titleInput3 {
    margin: 2vh 0;
    font-size: 20px;
    height: auto;
    padding: 1vh 1vw;
    width: 90%;
    margin-bottom: 3vh;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.titleInput4 {
    margin: 2vh 0;
    font-size: 17px;
    padding: 3vh;
    margin-bottom: 3vh;
    width: 70%;
  
    min-height: 250px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
  }


  .open {
    font-size: 20px;
    color: #fff;
    margin: 1vh 0;
    max-width: 200px;
    word-wrap: break-word;
    z-index: 999;
    text-align: center;
    width: auto;
  }
  
  .open:hover {
    color: goldenrod;
    cursor: pointer;
  }
  
  .hiddenBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:1rem;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  
    




.removeIcon {
    position: absolute;
    z-index: 9999;
    width: 30px;
    height: 30px;

    top: 0;

    left: 12px;
    margin-top: 12px;
    font-weight: bold;
    align-self: flex-start;
    cursor: pointer;
    background-color: #222;
    border-radius: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

  }

  .removeIcon:hover {
    background-color: #777;
  }

  .uniButton {

    font-size: 20px;
    padding: 6px;
    margin: 2vh 0;
    background-color: #222;
    color: white;
text-transform: uppercase;
border-radius: 20px;
font-weight: 200;


  }


  .uniButton:hover {
    cursor: pointer;
    background-color: #777;
  }


  .plusButtonDiv {
    width: 70px;
    height: 70px;
    padding: 2vh 0;
    z-index: 999;
}


.plusButton {

    color: rgb(73, 211, 73);

    width: 100%;
    height: 100%;
}

.plusButton:hover {
    color: green;
    cursor: pointer;



}



.newUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 1vh;

    padding: 2vh 0;
    font-size: 20px;
  }

  .newUpload > label{
    text-align: center;
    color: #fff;
  }

  .newUpload > img {
    object-fit: contain;
    width: 100px !important;
    height: 100px !important;
  }


  .exteriorWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 4vh;
}


 


  .uniButton2 {

    font-size: 20px;
    padding: 6px;
    margin: 2vh 0;
    background-color: #fff;
    color: black;
text-transform: uppercase;
z-index: 999;


  }


  .uniButton2:hover {
    cursor: pointer;
    background-color: #777;
  }


.typeSelection {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2;
}

.typeSelection > label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1vh;
}

.keywordDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:#c4c0c0  !important;

  min-width: 60%;
  width: auto;
  height: 100%;
  max-width:80%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 20px;
  padding: 2vh 0.7vw;
  transition: all 0.4s ease-in-out;
  gap: 1vh;

}


.contentInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    gap: 1vh;
    padding: 1vh 0;
}


.keywords2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 60%;
    max-width: 85%;
    padding: 4vh 1vh;
    gap: 1vh;
}

.keyword {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eadbdb !important;
   
    width: auto;
    height: 100%;
    max-width: 90%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 20px;
    padding: 2vh 0.7vw;
    padding-top: 4vh;
    transition: all 0.4s ease-in-out;
    gap: 1vh;

}

.container > h2 {
    font-weight: 200;
    font-size: 35px;
    color:#222;

}

.menus {
    width: 95%;
    height: auto;
    padding: 2vh;
    display: flex;
    align-items: center;
    gap: 2vh;
    justify-content: center;
    flex-wrap: wrap;

}

.liveMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3c7c7;
    width: 90%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 20px;
    padding: 2vh 0.7vw;
    transition: all 0.4s ease-in-out;
    gap: 1vh;


}


.formButton2 {
  
    
  font-size: 24px;
  padding: 8px;
  margin: 3vh 0;
  background-color: rgb(62, 193, 62);
 
  color: #222;
text-transform: uppercase;
border-radius: 25px;
font-weight: 400;
z-index: 999;
}


.formButton2:hover {

  cursor: pointer;
  
  background-color: green;
 

}


.liveMenu > h3 {
    font-size: 30px;
    color: #222;
    font-weight: 200;
    text-align: center;
}

.btnDiv  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

/*Modal styles for preview */


/* list property */

.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-bottom: 1vh;
  flex-direction: column;
  gap: 1rem;
  background-color:    #fdebf0 !important;
  position: relative;
  padding-bottom: 6vh;

  
}

.topDiv {
  width: 100%;
 height: 90vh;
  position: relative;
  background-color: black;

  
}

.topDiv > img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
}

.abso {
  position: absolute;
  height: 100%;
opacity: 0.8 !important;
  width:  100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 8;
}

.abso > span {
font-size: 70px;
 text-align: center;
 text-transform: uppercase;
  color: #fff;
  width: 80%;
}


.middleDiv {
  height: auto;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 1.3rem;
  padding: 3vh 2vh; 
}

.middleDiv > .specialP{

  font-size: 38px;

  color: #444;
  font-weight: 200;

}

.middleDiv > p {
  font-size: 20px;
  color: #444;
  font-weight: 200;
  
}

.photosDiv {

  width: 85%;
  display: flex;
  height: auto;

  gap: 2rem;

  transition: all 0.6s ease-in-out;
  }
  
  
 

.picDiv {

  height: 400px;
  width:100%;
  

}

.picDiv > img {
height: 100%;
width: 100%;
object-fit: cover;
}

.contactForm {
 
 
  height: auto;
  width: 100%;
padding: 10vh 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}

.contactFormWrapper {
  

  width:  35%;
  height: auto;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  background-color: #2d2c2c !important;
  flex-direction: column;
  justify-content: center;
  padding: 1.5vh 0.5vw;
  align-items: center;
   
 
  transform: translateX(-35%);
  opacity: 0;
  transition: all 0.6s ease-in-out;
 
 
}



.contactFormWrapper.appear {
  
  transform: translateX(0);
  opacity: 1;

}

.contactFormWrapper> .specialP {
  text-align: center;
  margin: 0.5rem 0;
  margin-bottom: 0.5rem;
  font-size: 38px;
  color: #444;
  font-weight: 200;
  color:  #fff7f9;
}

.contactFormWrapper> p {
  font-size:  20px;
  font-weight: 200;
 color:  #fff7f9;
  
}

.contactFormWrapper>form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1vh;
  width: 60%;
  
}

.contactFormWrapper>form>input {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-size: 17px;
  width: 70%;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
  color: #222;
  font-weight: 200;
}

.contactFormWrapper>form>textarea {

  height: 200px;
  width: 100%;
  font-size: 20px;
  font-weight: 200;


}

.contactFormWrapper>form>div>label {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  font-size: 19px;
  margin-right: 1rem;
}



.mess {
  width: 80%;
  text-align: center;
  color: #444;
  font-size: 19px;
}

.area {
  width: 300px;
  height: 150px;
 
  color: #222;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}

.carWrapper {
  width: 100%;
  height: 250px;

}

.contactFormWrapper button {
  margin-top: 0.5rem;
}

.wrapper {
  width: 100%;
  height: auto;
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;


  transition: all 0.2s ease-in;
}


.row:nth-child(even) {
  flex-direction: row-reverse;
/*  transform: translateX(15%);*/
  opacity: 1;
  transform: translateY(0);
  
}

.row:nth-child(4) {
  flex-direction: row-reverse;
 /* transform: translateX(-15%); */
  opacity: 1;
  transform: translateY(0);
}

.row:nth-child(3) {

 /* transform: translateX(-15%); */

}



.txtBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
  width: 50%;

  transition: all 0.2s ease-in;
 

}

#idThree {
  font-size: 15px !important;
}

.txtBox  > p{
  text-align: center;
  color: #444;
  font-size: 17px;
  width: 70%;


  font-weight: 200;
  
}



.imgCont {
  height: 600px;
  width: 50%;

  transition: all 0.2s ease-in;

}


.row:nth-child(odd) > .txtBox:nth-child(2) {

  opacity: 1 !important;
  transform: translateX(0) !important;
  transform: translateY(0) !important;
}

.row:nth-child(odd) > .imgCont:nth-child(1) {

  opacity: 1 !important;
  transform: translateX(0) !important;
  transform: translateY(0) !important;
}



.imgCont > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row.appear {
  opacity: 1;
  transform: translateY(0);
  
  
}

.imgCont.appear {
  opacity: 1;
  transform: translateX(0);
  
  
}

.txtBox.appear {
  opacity: 1;
  transform: translateX(0);
  
  
}

.getBtn, .giftBtn {
  background-color: #2d2c2c !important;
  font-weight: 200;
  
 text-decoration: none;
  padding: 0.4vh 0.4vw;
  height: 55px;
  display: flex;
  font-size: 18px;
  width: 130px;
  
      cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-in-out;
  color: #fff!important;
}

.specialP {
  font-size: 38px !important;
  color: #444 !important;
}


.a {
 
  text-align: center;
  text-decoration: none;
  transition: 0.2s all ease-in-out;

}






.getBtn:hover, .giftBtn:hover {
  background-color: #fff !important;
  color: #222 !important;
   
 

}

.error {
  height: 70px;
  width: 250px;
  text-align: center;
  border-radius: 20px;
  line-height: 1.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #f00;
  color: #fff;
  position: fixed;
  top: 5rem;
  right: 5rem;
  font-weight: 500;
}

.success {
  z-index: -5;
  height: 100vh;
  width: 100%;
  text-align: center;
font-size: 30px;
  line-height: 3.5rem;
  padding: 1.5rem;
  background-color: green;
  color: #fff;
  position: fixed;
  top: 0;
 
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
  
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-in-out;
  opacity: 0;
}

.desc > a {
  text-decoration: none;
  font-weight: 400;
  color:#2d2c2c;
  
  transition: all 0.2s ease-in-out;


}

.desc > a:hover  {

 
 color: rgb(167, 98, 44);

}



.hr {
  width: 30%;

  
}

.hr  > hr{
  align-self: left;
  margin: 2vh 0;

}


.success.show {
  opacity: 1;
  z-index: 999;
}





@media only screen and (max-height: 700px) {

  .abso > span {
      font-size: 30px;
   
      color: #fff;
  }




}


@media only screen and (max-width: 1200px) {


  .desc {
      display: none;
  }

  #descId{
      display: block;
  }


  #idFour {
      display: none;
  }

  .row {
      height: 400px;
  }

  .middleDiv > .specialP{

      font-size: 24px;
    
  
  }
  
  
    }
  






@media only screen and (max-width: 1000px) {

  .photosDiv {

      flex-direction: column;

  }

  
  .middleDiv > .specialP{

      font-size: 27px;
    
  
  }


  .picDiv {

      height: 460px;
      width:100%;
  
  }
  
  .contactFormWrapper>h2 {
      font-size: 29px;
  }

      
      


  .contactFormWrapper> p {
      font-size: 16px;
  }
  .contactFormWrapper>form>input {
      width: 70%;
  }


  .area {
      width: 80%;
      

  }
}

@media only screen and (max-width: 900px) {

  .contactFormWrapper {
      width:  60%;
      height: 60%;
     }

 .contactFormWrapper>h2 {
  font-size: 27px;
}

.success {

  height: 190vh;



}

.giftBtn {

  font-size: 13px;

  width: 80px;
  height: 50px;


}


}


@media only screen and (max-width: 850px) {

  .abso > span {
      font-size: 42px;
   
      color: #fff;
  }

  
.topDiv {

  height: 50vh;

}





}

@media only screen and (max-width: 760px) {

 

  .absoClass2 {

      width: 220px;
      height: 250px;
 
   
  
      }


      .homeSpecialP {
          font-size: 30px;
       
          color: #fff;
      }
      .contactFormWrapper>form>textarea {


          font-size: 15px;
          
      
      
      }

}

@media only screen and (max-width: 660px) {

 

  .absoClass2 {

      width: 220px;
      height: 250px;
 
   
  
      }


      .abso >  span {
          font-size: 27px;
       
          color: #fff;
      }


}



@media only screen and (max-width: 600px) {


  .wrapper {
      flex-wrap: wrap;
      gap: 2rem;
      padding: 0.5vh 0;
  }


  .row {
     
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5vh 0;
      height: auto;
      padding: 0px;


  }

  .top {
      padding: 1vh 0;
  }


  .desc {
      display: block;
  }

  

  #idFour {
      display: flex;
  }


  .hr {
      width: 50%;
   
      
  }
  

  .imgCont {
      width: 95%;
      height: auto;


      
  }

  .imgCont > img {
      height: 350px;
  }

  .txtBox  {
      padding-top: 3vh;
      width: 100%;
  }

  

  .txtBox > p {

      font-size: 16px;


  }


  .row:nth-child(even) {
      flex-direction: column;
  }

  .top {
      margin: 5vh 0;
      height: auto;

  }

.top > p {
  font-size: 16px ;
}


.specialP {
  font-size: 23px !important;

}


  .picDiv {

      height: 270px;
      width:100%;
  
  }

 

  .contactFormWrapper {
      width:  85%;
   
      padding: 0.6vh 0.5vw;
     }

     .getBtn {
     
      padding: 0.4vh 0.4vw;
     width: 40%;
      font-size: 14px;
     
  }

  .contactFormWrapper button {
      margin-top: 0.2rem;
  }

  .contactFormWrapper>form>textarea{
      width: 100%;
  }
  .contactFormWrapper>form>input {
      width: 90%;
      font-size: 15px;
  }
  

     .contactFormWrapper >  p {
      font-size:13px
     }

     .contactFormWrapper> .specialP {
      font-size: 24px;
      margin-bottom: 0.2rem;
  }

  .middleDiv > .specialP{

      font-size: 23px;
    
  
  }
  
  .middleDiv > p {
      font-size: 16px;
  
      
  }



    

  .area {
      width: 90%;
     height: 120px;
      font-size: 13px;
      
  }

  
 

  .abso > span {
      font-size: 32px;
      width: 80%;

  }


}

@media only screen and (max-width: 300px) {

  .picDiv {

      height: 200px;
      width:100%;
  
  }

  .abso > span {
      font-size: 27px;
      width: 70%;

  }



}

@media screen and (min-width: 1022px) and (max-height: 822px) {



  
  .abso > span {
      font-size: 55px ;
      
      
      width: 90%;
  }



 
  
  
}