/* list property */

.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color:    #fdebf0 !important;
    position: relative;
    padding-bottom: 6vh;
 
    
}

.topDiv {
    width: 100%;
    height: 90vh;
    position: relative;
    background-color: black;

    
}

.topDiv > img {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    object-fit: cover;
}

.abso {
    position: absolute;
    height: 100%;
  opacity: 1 !important;
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 8;
}

.abso > span {
    font-size: 70px;
   text-align: center;
    color: #fff;
}


.middleDiv {
    height: auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 1.3rem;
    padding: 3vh 0; 
}

.middleDiv > .specialP{

    font-size: 38px;
  
    color: #444;
    font-weight: 200;

}

.middleDiv > p {
    font-size: 20px;
    color: #444;
    font-weight: 200;
    
}

.photosDiv {

    width: 85%;
    display: flex;
    height: auto;

    gap: 2rem;

opacity: 0;
transform: translateX(15%);
transition: all 0.6s ease-in-out;
}


.photosDiv.appear {
opacity: 1;
transform: translateX(0);


}

.picDiv {

    height: 400px;
    width:100%;

}

.picDiv > img {
height: 100%;
width: 100%;
object-fit: cover;
}

.contactForm {
   
   
    height: 800px;
    width: 100%;
  
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.contactFormWrapper {
    
 
    width:  40%;
    height: 70%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    background-color: #2d2c2c !important;
    flex-direction: column;
    justify-content: center;
    padding: 1.5vh 0.5vw;
    align-items: center;
     
   
    transform: translateX(-35%);
    opacity: 0;
    transition: all 0.6s ease-in-out;
   
   
}


.contactFormWrapper.appear {
    
    transform: translateX(0);
    opacity: 1;

}

.contactFormWrapper> .specialP {
    text-align: center;
    margin: 0.5rem 0;
    margin-bottom: 0.5rem;
    font-size: 38px;
    color: #444;
    font-weight: 200;
    color:  #fff7f9;
}

.contactFormWrapper> p {
    font-size:  20px;
    font-weight: 200;
   color:  #fff7f9;
    
}

.contactFormWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1vh;
    
}

.contactFormWrapper>form>input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 17px;
    width: 90%;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
    color: #222;
    font-weight: 200;
}

.contactFormWrapper>form>div>label {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-size: 19px;
    margin-right: 1rem;
}

.mess {
    width: 80%;
    text-align: center;
    color: #444;
    font-size: 19px;
}

.area {
    width: 300px;
    height: 150px;
   
    color: #222;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}

.carWrapper {
    width: 100%;
    height: 250px;

}

.contactFormWrapper button {
    margin-top: 0.5rem;
}

.getBtn {
    background-color: #f3c7c7 !important;
    
   text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 7vh;
    display: flex;
    font-size: 18px;
    width: 120px;
    font-weight: 200;
        cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #444!important;
  }



  


  .getBtn:hover {
    background-color: #fff !important;
    color: #222 !important;
      
     
   
 
  }

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
}

.success {
    z-index: -5;
    height: 100vh;
    width: 100%;
    text-align: center;
 font-size: 30px;
    line-height: 3.5rem;
    padding: 1.5rem;
    background-color: green;
    color: #fff;
    position: fixed;
    top: 0;
   
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms all ease-in-out;
    opacity: 0;
}


.hr {
    width: 40%;
 
    
}

.hr  > hr{
    align-self: left;
    margin-bottom: 1vh;

}

  
  .success.show {
    opacity: 1;
    z-index: 999;
  }

  @media only screen and (max-width: 1000px) {

    .photosDiv {

        flex-direction: column;

    }

    .middleDiv > .specialP{

        font-size: 27px;
      
    
    }
    .picDiv {

        height: auto;
        width:100%;
    
    }

    .picDiv > img {
        object-fit: contain;
    }


    
    
    .contactFormWrapper>h2 {
        font-size: 29px;
    }

        
        


    .contactFormWrapper> p {
        font-size: 16px;
    }
    .contactFormWrapper>form>input {
        width: 80%;
    }


    .area {
        width: 80%;
        

    }
}



@media only screen and (max-width: 850px) {

    .abso > span {
        font-size: 36px;
     
        color: #fff;
    }

    
.topDiv {

    height: 50vh;

}


  

}



@media only screen and (max-width: 760px) {

   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }


        .abso > span {
            font-size: 35px;
         
            color: #fff;
        }


}






@media only screen and (max-width: 900px) {

    .contactFormWrapper {
        width:  60%;
        height: 60%;
       }

   .contactFormWrapper>h2 {
    font-size: 27px;
}

.success {

    height: 190vh;



}


}

@media only screen and (max-width: 660px) {

   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }

        .middleDiv > .specialP{

            font-size: 23px;
          
        
        }

        .abso >  span {
            font-size: 27px;
         
            color: #fff;
        }


}



@media only screen and (max-width: 600px) {

  

    .contactFormWrapper {
        width:  70%;
     
        padding: 0.6vh 0.5vw;
       }

       .getBtn {
       
        padding: 0.4vh 0.4vw;
       
        font-size: 14px;
       
    }

    .contactFormWrapper button {
        margin-top: 0.2rem;
    }

   
    

       .contactFormWrapper >  p {
        font-size:13px
       }

       .contactFormWrapper> .specialP {
        font-size: 24px;
        margin-bottom: 0.2rem;
    }

    .middleDiv > .specialP{

        font-size: 28px;
      
    
    }
    
    .middleDiv > p {
        font-size: 16px;
    
        
    }


 
      

    .area {
        width: 90%;
       height: 120px;
        font-size: 13px;
        
    }

    
    .contactFormWrapper>form>input {
        width: 90%;
        font-size: 15px;
    }



}


@media only screen and (max-width: 300px) {




}




@media screen and (min-width: 1022px) and (max-height: 822px) {



    .abso >span {
        font-size: 55px ;
        
        
        width: 90%;
    }



   
    
    
}