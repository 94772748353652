.listPropertyWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3c7c7!important;
    width: 60%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
  color: #fff;
    border-radius: 20px;
    padding: 2vh 0.7vw;
    transition: all 0.4s ease-in-out;

    margin: 4vh 0;
   
  
  }

  .uniButton {

    font-size: 20px;
    padding: 8px;
    margin: 2vh 0;
    background-color: #222;
    color: white;
text-transform: uppercase;
border-radius: 20px;
font-weight: 200;


  }


   .uniButton:hover {
    cursor: pointer;
    background-color: #777;
  }

  
  .listPropertyWrapper>h2 {
    text-align: center;
    margin: 0.2rem 0;
    margin-bottom: 3vh;
    font-size: 38px;
    color: #fff;
    font-weight: 200;
  }

  
  
  .listPropertyWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff !important;
    gap: 0.4rem;
    width: 95%;
    font-weight: 200;
  }
  
  
  .listPropertyWrapper>form>div>label {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #222;
    font-size: 19px;
    margin-right: 1rem;
  }
  
  
  form>.inputs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  
   
  }

  .inputs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    width: 100%;
   
  
   
  }
  
  
  .inputs>label {
    font-size: 20px;
    width: 90%;
    color: #fff;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  
  
  .inputs>label>input {
    font-size: 17px;
  border: 2px #fff solid;
  padding: 6px;
  }


  
  
  
  
  
  
  
  .listPropertyWrapper>form>select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #222;
    outline: none;
    padding: 0.5rem 0.25rem;
    width: 80%;
  }
  
  
  
  
  
  .scripTitle {
    font-weight: 200;
    font-size: 28px;
    margin: 2vh 0;
    margin-top: 4vh;
    text-transform: uppercase;
    text-align: center;
  

    color: #fff; 
  }
  
  .scripTitle > span {
  
    color: green;
    font-size: 20px;
  }


   
  .scripTitle:hover {
    color: goldenrod;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

  }
  
  .changeRep {
    font-size: 17px;
    text-align: center;
  
  }
  
  .options {
    width: 80%;
    font-size: 18px;
  }
  
  .scrip {
  
    width: 95%;
  
    font-size: 18px;
    height: auto;
    
    margin: 1vh 0;
    min-height: 200px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 6px;
  }
  
  .types {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 18px;
    color: #222;
    gap: 1rem;
  }
  
  .types2 {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    justify-content: space-evenly;
  }
  
  .upload {
    display: flex;
    align-items: center;
    flex-direction: column ;
   
    width: 100%;
    color: #fff;
    justify-content: space-between;
  }
  
  
  
  .type>label {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 2vh;
  }
  
  .removeIcon {
    position: absolute;
    z-index: 999;
    width: 22px;
    height: 22px;

    top: 0;

    margin-left: 6px;
    margin-top: 12px;
    font-weight: bold;
    align-self: flex-start;
    cursor: pointer;
    background-color: #222;
    border-radius: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

  }

  .removeIcon:hover {
    background-color: #777;
  }
  
  
  
  .radio {
    padding-top: 2vh;
    display: flex;
    font-size: 17px;
    gap: 2rem;
  }
  
  .radio>label>input {
    margin-right: 0.5vw;
  }
  
  

  
  
  .keywordDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    margin-bottom: 1vh;
  }
  
  
  .speshInput {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 14px;
    border: 0;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.09);
    width: 180px;
  }

  .photoSection {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
  }


  .newUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 1vh;
    font-size: 20px;
  }

  .newUpload > label{
    text-align: center;
    color: #fff;
  }

  .newUpload > img {
    object-fit: contain;
    width: 100px !important;
    height: 100px !important;
  }
  

  .openWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    overflow: hidden;
    gap: 1rem;
    width: 100%;
  }

  .openWrapper > h6 {
    font-size: 22px;
    width: 80%;
    text-align: center;
    font-weight: 200;
    color: #fff;
  }
  
  .btnUpload2 {
    color: #fff;
    display: flex;
    cursor: pointer;
    font-weight: 600;
    padding: 0.5vh;
    border: 2px #fff solid;
    transition: all 0.2s ease-in-out;
   
  
  
  }
  
  .btnUpload2:hover {
    color: lightgreen;;
    border: 2px lightgreen solid;
  
  }
  
  
  .photoDel {
    font-weight: 600;
    color: #222;
    background: #9f9f9f;
    cursor:pointer;
  }
  
  .photoDel:hover {
   
    background: #fff;
    
  }
  
  
  .keywords {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  
  .keywords2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
 
    gap: 0.6rem;
    margin-bottom: 3vh;
  }
  


  .plusButtonDiv {
    width: 70px;
    height: 70px;
    padding: 6vh 0;
    z-index: 999;
  }
  
  
  .plusButton {
  
    color: rgb(73, 211, 73);
  
    width: 100%;
    height: 100%;
  }
  
  .plusButton:hover {
    color: green;
    cursor: pointer;
  
  
  
  }
  
  .keywords2 > .keyword {
    display: flex;
    flex-direction: column;
     align-items: center; 
     justify-content: space-between; 
     width: 300px; 
     height: 300px;
     font-size: 16px; 
     padding: 5px;
   
     position: relative;
  
  }


  
  
  .keywords2 > .keyword  > p {
    text-align: center;
  }
  
  .formButton2 {
  
    
    font-size: 24px;
    padding: 8px;
    margin: 3vh 0;
    background-color: rgb(62, 193, 62);
   
    color: #222;
  text-transform: uppercase;
  border-radius: 25px;
  font-weight: 400;
  
  }
  
  
  .formButton2:hover {
  
    cursor: pointer;
    
    background-color: green;
   
  
  }
  
  .photoUnit {
    display: flex;
    flex-direction: column;
  }
  
  .map {
    margin-top: 2vh;
    width: 92%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  
    transition: opacity 0.5s ease-in;
  }


  .titleInput2 {
    margin: 2vh 0;
    font-size: 18px;
    padding: 1vh 1vw;
   max-width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  
  
  
  @media only screen and (max-width: 1105px) {
  
  
    .topBar {
  
      gap: 3rem;
  
    }
  
  
  
  
  
  
    .section>h1 {
      color: #fff;
      font-size: 40px;
      padding-top: 3vh;
      text-align: center;
  
  
    }
  
    .section>img {
      height: 100px;
      width: auto;
    }
  
  
    .profileImg {
      margin-top: 2vh;
      border-radius: 50%;
      height: 120px;
      overflow: hidden;
      width: 120px;
      cursor: pointer;
  
    }
  
    .profileImg>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
  
  
  
  
  }
  
  
  @media only screen and (max-width: 900px) {
  
    .listPropertyForm {
  
  
  
      width: 80%;
    }
  

  
   
  
  
  
  
  
  

  
    .section>h1 {
      display: none;
  
  
    }
  
    .topBar {
  
      gap: 2rem;
  
    }
  
  
  
  
  
  }
  
  
  @media only screen and (max-width: 800px) {


    .inputs {
      align-items: center;
    }
  
  
    
  
    .inputs>label {
      flex-direction: column;
      align-items: center;
     
  
  
    }
  
 
    .formButton2 {
  
      width: 60%;
      padding: 0 1vw;
  
      font-size: 20px;
  
  
    }
  
 
  
    .wrapper > h2 {
      font-size: 30px;
    }
  
    .property>.imgContainer {
      height: 110px;
    }
  
    .scrip {
      font-size: 16px;
    }
  
    .property>.details>.propName {
      font-size: 18px;
  
  
    }
  
    

  
    .listPropertyWrapper>h2 {
      font-size: 30px;
    }
  
    .listPropertyForm {
  
  
  
      width: 90%;
    
    }
  
    .keywordDiv {
      gap: 2rem;
      flex-direction: column;
      
    }
  
    .keywords2 {
      flex-direction: column;
    }
  
    .keyword {
      width: auto !important;
    }

    

 
  
  
   
  
    .btnUpload2 {
      font-size: 18px;
      
    }
  
    .types {
      gap: 2rem;
    }
  
    .formButton2 {
  
      width: 70%;
      padding: 0 1vw;
  
  
    }
  
    .radio {
      flex-wrap: wrap;
  
      gap: 1rem;
    
      font-size: 16px;
    }
  
  
    .section>img {
      height: 60px;
      width: auto;
    }
  
    .profileImg {
  
      height: 80px;
  
      width: 80px;
  
    }
  
    
  
    .inputs {
      width: 80% !important;
    }
  
    .inputs>label {
      text-align: center;
      
     
    }
  
  
  
  
  
  }
  
  
  @media only screen and (max-width: 300px) {
  
   
  
    .inputs>label>input {
     
      width: 100%;
    
    }
    .listPropertyWrapper > form {
      width: 85%;
    }
  
    .keywordDiv {
      width: 90%;
      flex-direction: column;
    }
  
    .keyword {
      width: 80% !important;
    }
  

  
    
  
  
  

 
  
    .topBar {
      margin: 0 1vw;
      gap: 1rem;
    }
  
 
    .selectorDiv {
      width: 100%;
    }
  
  
  
  
  }