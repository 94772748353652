.dropdown {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 18px;
 
    background-color: transparent;
   

  }

  
  .form {
    display: flex;
    background: transparent;
    position: relative;
    width: 100%;
  }
  
 

  
  .chevron {
   padding-top: 0.2vh;
    transform: rotate(0deg);
    transition: all 0.2s;
    font-weight: 200;
  }
  
  .chevron.active {
    transform: rotate(180deg);
  }
  

  .button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    background: transparent;
    gap: 0.4rem;
    color: #444;
    font-size: 18px;
    cursor: pointer;
    font-weight: 200;
    width: 100%;
    transition: color 0.2s ease-in-out;
    
  }

 

  .dropdownList {
   
    position: absolute;
    display: flex !important;
    flex-direction: column;
    align-items: left !important;
    padding-top: 25px !important;
    padding-right: 25px !important;
    padding-left: 0 !important;
    
   
    top: 26px;
   left: -24px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 800ms, visibility 800ms;
    transition: opacity 800ms, visibility 800ms;
    justify-content: space-around !important;
    background-color: #f3c7c7 !important;
    z-index: 999;
    width: auto;
    height: auto;
    gap: 0 !important;
   

    
  }
  .dropdownList > li  {
padding-left: 23px;
padding-bottom: 2vh;
white-space: nowrap;


  }
  

  .dropdownList > li > a {
    color: #444;
    text-decoration: none;
    transition: color 0.4s ease-in-out;
    text-transform: uppercase;
  }

  .dropdownList > li > a:hover {
    color: #777;



  }
  
  
  .dropdownList.show {
   
    opacity:  1 !important;
    visibility: visible;

  

  }
  
  .dropdown > ul > li {
    font-weight: 200;


  }
  
  .dropdown > ul > li:hover {
    color: #999;
    transition: all 0.5s ease;
  }


  @media (max-width: 1000px) {

    .dropdownList {

      left: 0px;
      width: 100% !important;
      align-items: center;
     
      padding-top: 5px !important;

    }



    .button {

      padding-left: 22px;


    }

    

    .dropdownList > li  {
      padding-left: 0vh;
      padding-right: 0vh !important;
      padding-bottom: 0vh;
      padding: 6px;
      padding-left: 0;
      white-space: nowrap;
      
      
        }


  }