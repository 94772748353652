.container {
    width: 100%;
    height: auto;
    display: flex;
    padding: 3vh 0;
    justify-content: center;
    color: #444;
    align-items: center;
    background-color: #f3c7c7 !important;
    position: relative;
    z-index: 999;
   
}

.wrapper {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 4rem;
    align-items: center;

}

.box {
   
    height: 60.2%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;

}


.box > span {
   
    font-size: 16px;
   color: #444;
    gap: 2rem;
    font-weight: 200;
}

.box > span > a {
    color: #444;
    text-decoration: none;
}



#imgBox > a {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#imgBox > a > img  {
    width: 100px;
    height: 100px;
}

.box > span > a:hover {
    color: #777;



  }

#iconRow {
    flex-direction: row !important;
    justify-content: space-between;

}

.iconSpan {
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

  
}

.icon {
    width: 22px;
    height: 22px;
    color: #222;
}


.icon2 {
    width: 22px;
    height: 22px;
    color: #222;
 
   
}



@media only screen and (max-width: 900px) {

    #iconRow {
        gap: 1rem;
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
       
        height: 100%;
        
    }

   



    .box {

        gap: 0.6rem;
        flex-direction: column;
       height: 100%;
        justify-content: center;
        align-items: center;

    }

    .box > span {
        font-size: 17px;
        width: 100%;
    }

    .box > span > a {
        display: block;
        font-size: 17px;
        margin: 0;
        width: 100%;
    }

    #imgBox  {
        display: none;
    }

   

    .wrapper {

gap: 6.1rem;


    }



}

@media only screen and (max-width: 600px) {

    
    #iconRow {
        gap: 1rem;
        display: flex;
        flex-direction: column !important;
        align-items: center;
       
        height: 100%;
        
    }

    .iconSpan {
        margin-top: 2vh;
    }


    .icon {
        width: 17px;
        height: 17px;
        color: #222;
    }
 

    #topBox {
        display: none;
    }

    .box > span {
        font-size: 18px;
    }

    .wrapper {

        gap: 2.4rem;

    }


}


@media only screen and (max-width: 300px) {

    .box > span {
        font-size: 11px;
    }

    .wrapper {

        gap: 2rem;

    }



}
