.homeContainer {
    width: 100%;
    position: relative;
    overflow: hidden; /* Optional: hides any content that exceeds the container's dimensions */
  }
  

  #TA_excellent855 {
    margin: 0 ;
  }

#fctwrphNONG {
  width: 100% !important;
  list-style-type: none;
  height: 100%;
}



 
.widEXCIMG {
margin-top: 5px;
  width: 90%;
  height: 30px;

}

@media only screen and (max-width: 1000px) {

   
.widEXCIMG {


  height: 30px;

}


}