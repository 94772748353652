

.hoursWrapper {
    
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f3c7c7 !important;
        width: auto;
        height: 100%;
        max-width: 90%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        color: #fff;
        min-width: 50%;
        border-radius: 20px;
        padding: 2vh 2vw;
        transition: all 0.4s ease-in-out;
    
}



.hoursWrapper>h2 {
  font-weight: 200;
  font-size: 30px;
  width: 80%;
  margin: 2vh 0;
  text-align: center;
  transition: 0.2s all ease-in-out;





}


.hoursWrapper>h2:hover {
  cursor: pointer;
  color: goldenrod;
}



.removeIcon {
    position: absolute;
    z-index: 999;
    width: 20px;
    height: 20px;

    top: 0;

    margin-left: 6px;
    margin-top: 12px;
    font-weight: bold;
    align-self: flex-start;
    cursor: pointer;
    background-color: #222;
    border-radius: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

  }


  .removeIcon:hover {
    background-color: #777;
  }


  .uniButton {

    font-size: 18px;
    padding: 6px;
    margin: 2vh 0;
    background-color: #222;
    color: white;
text-transform: uppercase;
border-radius: 20px;
font-weight: 200;
z-index: 999;


  }


  .uniButton:hover {
    cursor: pointer;
    background-color: #777;
  }


  .plusButtonDiv {
    width: 70px;
    height: 70px;
    padding: 2vh 0;
}


.plusButton {

    color: rgb(73, 211, 73);

    width: 100%;
    height: 100%;
}

.plusButton:hover {
    color: green;
    cursor: pointer;



}


.open {
  font-size: 20px;
  color: #fff;
  margin: 1vh 0;
  text-align: center;
  word-wrap: break-word;
  z-index: 999;
  max-width:200px;
}

.open:hover {
  color: goldenrod;
  cursor: pointer;
}

.open > span {
  font-weight: 600;
}
  .hiddenBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:1rem;
    justify-content: center;
    transition: all 0.2s ease-in-out;
  }
  
  
.hoursWrapper > h2 {
        font-weight: 200;
        font-size: 30px;

        text-align: center;
       
    }

.keywords2 {
        display: flex;
        align-items: center;
    max-width: 90%;
        justify-content: center;
        flex-direction: column;
        padding: 3vh 1vh;
        gap: 1vh;
    }


    .titleInput2 {
        margin: 2vh 0;
        font-size: 20px;
        padding: 1vh 1vw;
        margin-bottom: 3vh;
        max-width: 90%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        border-radius: 18px;
      }

      
    .titleInput3 {
      margin: 2vh 0;
      font-size: 20px;
      padding: 1vh 1vw;
      margin-bottom: 3vh;
      max-width: 90%;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
    }


      
 .titleInput4 {
    margin: 2vh 0;
    font-size: 17px;
    padding: 3vh;
    margin-bottom: 3vh;
  
    min-height: 200px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
  }


  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
    color: #222;
  }
    
      
    
    .keyword {
        position: relative;
        display: flex;
      flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #eadbdb !important;
        width: auto;
        height: 100%;
        font-size: 18px;
        max-width: 90%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        color: #fff;
        border-radius: 20px;
        padding: 3vh 0.7vw;
        padding-top: 4vh;
        transition: all 0.4s ease-in-out;
        gap: 1vh;
    
    }

    .keyword > p {

      max-width: 200px;
      text-align: center;
      word-wrap: break-word;

    }
    
    .formButton2 {
  
    
      font-size: 24px;
      padding: 8px;
      margin: 3vh 0;
      background-color: rgb(62, 193, 62);
     
      color: #222;
    text-transform: uppercase;
    border-radius: 25px;
    font-weight: 400;
    z-index: 999;
    }
    
    
    .formButton2:hover {
    
      cursor: pointer;
      
      background-color: green;
     
    
    }


    .typeSelection {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 2;
    }
    
    .typeSelection > label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.2vh;
        margin: 1vh 0;
    }
    
    .keywordDiv {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #c4c0c0  !important;
        width: 90%;
        height: 100%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        color: #fff;
        border-radius: 20px;
        padding: 2vh 0.7vw;
        transition: all 0.4s ease-in-out;
        gap: 1vh;
        z-index: 999;
    
    }
    
    
    .contentInputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
        gap: 1vh;
        padding: 1vh 0;
    }
    
    
    @media (max-width: 600px) {



      .titleInput2 {
          max-width: 80% !important;
      }

      .titleInput4 {
        max-width: 65% !important;
      }
      .titleInput3 {

        max-width: 100%;
      }
          
  
   
    }  
 

 