.container {
    height: auto;
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:    #fdebf0 !important;
}

.top {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2vh 0;

}

.top > p {
    text-align: center;
  
    font-size: 18px;
    color: #444;
    font-weight: 200;
    width: 70%;
}


.wrapper {
    width: 100%;
    height: auto;
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.row {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    opacity: 0;
   transform: translateY(7%);
    transition: all 0.2s ease-in;
}


.row:nth-child(even) {
    flex-direction: row-reverse;
  /*  transform: translateX(15%);*/
    opacity: 1;
    transform: translateY(0);
    
}

.row:nth-child(4) {
    flex-direction: row-reverse;
   /* transform: translateX(-15%); */
    opacity: 1;
    transform: translateY(0);
}

.row:nth-child(3) {
  
   /* transform: translateX(-15%); */
    opacity: 0;
    transform: translateY(-7%);
}



.txtBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.3rem;
    width: 50%;
    transform: translateX(-7%);
    opacity: 0;
    transition: all 0.2s ease-in;
   

}

.txtBox  > p{
    text-align: center;
    color: #444;
    font-size: 18px;
    width: 70%;
  
 
    font-weight: 200;
    
}



.imgCont {
    height: 100%;
    width: 50%;
    transform: translateX(7%);
    opacity: 0;
    transition: all 0.2s ease-in;

}


.row:nth-child(odd) > .txtBox:nth-child(2) {

    opacity: 1 !important;
    transform: translateX(0) !important;
    transform: translateY(0) !important;
}

.row:nth-child(odd) > .imgCont:nth-child(1) {

    opacity: 1 !important;
    transform: translateX(0) !important;
    transform: translateY(0) !important;
}



.imgCont > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.row.appear {
    opacity: 1;
    transform: translateY(0);
    
    
  }

.imgCont.appear {
    opacity: 1;
    transform: translateX(0);
    
    
  }

  .txtBox.appear {
    opacity: 1;
    transform: translateX(0);
    
    
  }

.hashtags {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.hashtags > p {
   
    text-align: center;
    font-size: 18px;
    color: #444;
    font-weight: 200;
}

.hr {
    width: 50%;
 
    
}

.hr  > hr{
    align-self: left;
  

}

.specialP {
    font-size: 38px !important;
    color: #444 !important;
    text-transform: uppercase;
    font-weight: 200 !important;
    
}

.giftBtn {
    background-color: #2d2c2c !important;
    font-weight: 200;
    
   text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 55px;
    display: flex;
    font-size: 18px;
    width: 130px;
    
        cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #fff!important;
  }

  .a {
   
    text-align: center;
    text-decoration: none;
    transition: 0.2s all ease-in-out;

  }

  


  .giftBtn:hover {
    background-color: #fff !important;
    color: #222 !important;
      
     
   
 
  }


  .desc {
    margin-bottom: 2vh;
  }


 

  @media only screen and (max-width: 1200px) {


.desc {
    display: none;
}

#idFive {
    display: none;
}

#idFour {
    display: none;
}


#idFive {
    display: none;
}

.specialP {

    font-size: 25px !important;


}

.row {
    height: 400px;
}

  }




  @media only screen and (max-width: 900px) {


    .giftBtn {

        font-size: 13px;

        width: 80px;
        height: 50px;


    }

  




  }

  

  @media only screen and (max-height: 700px) {

    .specialP {
        font-size: 25px !important;
        color: #444 !important;
    }

   

 


}

  @media only screen and (max-width: 600px) {

    .wrapper {
        flex-wrap: wrap;
        gap: 2rem;
        padding: 0.5vh 0;
    }


    .row {
       
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5vh 0;
        height: auto;
        padding: 0px;


    }

    .top {
        padding: 1vh 0;
    }


    .desc {
        display: block;
    }


    #idFour {
        display: flex;
    }


    #idFive {
        display: block;
    }

    .imgCont {
        width: 95%;


        
    }

    .imgCont > img {
        height: 350px;
    }

    .txtBox  {
        padding-top: 3vh;
        width: 100%;
    }

    

    .txtBox > p {

        font-size: 16px;


    }


    .row:nth-child(even) {
        flex-direction: column;
    }

    .top {
        margin: 5vh 0;
        height: auto;

    }

.top > p {
    font-size: 16px ;
}


.specialP {
    font-size: 23px !important;
 
}


}