/* Navbar.css */

.container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3c7c7 !important;
 width: 100%;
position: relative;
z-index: 990;
}




.navbar {

  width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3vw;

  gap: 3.6rem;
    color: #222;
    height: 100px;
    font-size: 18px;
    font-weight: 200;

background-color: #f3c7c7 !important;
    transition: transform 0.2s ease-in-out;
  }
  
  .logo {
    font-weight: bold;
    font-size: 20px;
    height: 100px;
    width: 130px;
  }
  
  .menu {
    display: flex;
   
    justify-content: space-evenly;
    

   
    
    
  }
  

  .menu1 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    

   
    
    
  }

  .rightBox {
    width: 10%;
  }

  .navbar > a  {
    width: 65px;
  }

  .navbar> a > img {
    padding-top: 0.8vh;
  
    width: 100%;
    height: 80px;
    
  }


  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
   
    justify-content: space-around;
   gap: 2.5rem;

  

    
  }

  .menu1 ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    gap: 2.5rem;
    

    
  }
  
  .menu > ul > li {
   
width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  
  }



  
  .menu > ul > li > a {
    color: #444;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
   

  
  }

  .menu > ul > li > a:hover {
    color: #777;



  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .line {
    width: 25px;
    height: 3px;
    background-color: black;
    margin-bottom: 5px;
  }

  .giftBtn {
    background-color: #2d2c2c !important;
    
   text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 60px;
    width: 130px;
    display: flex;
    font-size: 16px;
 font-weight: 200;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #fff!important;
  }

 

   .a {
   
    text-align: center;
    text-decoration: none;
    transition: 0.2s all ease-in-out;

  }

  


  .giftBtn:hover {
    background-color: #fff !important;
    color: #222 !important;
      
     
   
 
  }


  
  @media (max-width: 1000px) {
    .menu {
     

      display: flex;
      flex-direction: column;
      position: absolute;
      align-items: center;
      justify-content: center;
      top: 90px;
      
      width: 100%;
      background-color: #fff7f9;
      color: black;
      padding: 1vh 0;
      z-index: 999;
        opacity: 0;
        visibility: hidden;
      transition: all 0.1s ease-in-out;
    }
  
    
  
    .menu.open {
      opacity: 1;
      visibility: visible;
      
    }

      
  

   


      .menu > ul {
        display: flex;
          justify-content: center !important;
         flex-direction: column;
         gap: 1.5rem;
      }


      
  .menu > ul  {
    width: 100%;


  }
      
.navbar {

  width: 100%;


}
  
    .hamburger {
      display: flex;
    }
  }

  @media (max-width: 900px) {

    .giftBtn {
      width: 70px;
      height: 50px;
      font-size: 10px;
      margin-left: 0.4vw;

  }

    .navbar > a  {
      width: 40px;
    }
    .navbar> a > img {
     
    
      width: 45px;
      height: 60px;
      
    
    
    }


    .rightBox {
      width: auto;
    }
  

  }

  @media (max-width: 500px) {
    .giftBtn {
      width: 52px;
      height: 40px;
      font-size: 8.5px;
      margin-left: 0.4vw;

  }

  }


  @media (max-width: 500px) {
    .rightBox {
      display: none;
    }

  }





 
  @media (max-width: 300px) {


    .giftBtn {
      width: 50px;
      height: 33px;
      font-size: 8px;
      margin-left: 0.4vw;

  }

  .navbar {
    gap: 2.6rem;
  }


  }