.container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    background-color:  #fdebf0 !important;  
  
}

.wrapper {
    height: 98%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 2rem;
    justify-content: center;
    padding-top: 4vh;
  
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.6s ease-in-out;
}

.wrapper.appear {
    opacity: 1;
    transform: translateY(0);
    
    
  }

.wrapper >  .specialP {
    font-size: 38px !important;
    color: #444 !important;
    font-weight: 200;
}

.wrapper > p {
    font-size: 20px;
    text-align: left;
    color: #444;
    font-weight: 200;
}

.icons {
    width: 50%;
    display: flex;
    gap: 2;
    justify-content: space-between;
}

.icons > span {
    font-size: 19px;
    color: #444;
    font-weight: 200;
}

.row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    
    padding: 5vh 0;
    gap: 2rem;

}

.box {
    display: flex;
    height: 150px;
    align-items: left; 
    flex-direction: column;
    justify-content: flex-start;
   

    width: 25%;
    height: auto;
    gap: 1rem;
}

.box > p > a {
    text-decoration: none;

}

.box > p {
    font-size: 17px;
    color: #444;
    font-weight: 200;
}

.box > .specialP {
    font-size: 25px !important;
    color: #444 !important;
    font-weight: 200;
}
.box > h4 {
    font-size: 25px;
}

.parkingDetails {
    font-size: 19px !important;
}
.hr {
    width: 30%;
}

.hr  > hr{
    margin-bottom: 1vh;

}



@media (max-width: 1000px) {
.newCont > .specialP{

    font-size: 27px;
  

}

}

@media (max-width: 1250px) {


    .icons {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     }


}




@media (max-width: 900px) {



     .row {
        flex-direction: column;
     
     
       }



       .box  {

        width: 100%;


       }
      



}

@media (max-width: 600px) {
 
  .wrapper > .specialP {
      font-size: 23px !important;
  
     
      
  }




  
  .wrapper > p {
      font-size: 16px;
      
  }

 


  .icons > span {
    font-size: 16px;
    color: #444;
}

.box > .parkingDetails {
    font-size: 16px !important;
}


.box > .specialP {
    font-size: 19px !important;
   
}









}


@media (max-width: 300px) {

.icons > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;

   }


}