.container {
    padding-top: 6vh;
    padding-bottom: 6vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color:   #fef1f1 !important;;
    gap: 1rem;
}

.container > .specialP {
    font-size: 34px !important;
    color: #444;
    width: 80%;
    text-align: center;
    font-weight: 100;
}

.container > h5 {
    font-size: 34px;
    font-weight: 200;
}

.specialP {
    font-size: 21px;
    font-weight: 100;
    margin: 1vh 0;
  
  
    
}

.message {
    font-size: 16px;
    font-weight: 300 !important;

    color: #777;
    margin: 1vh 0;
   font-style: italic;
    width: 40%;
    text-align: center;
}




.dish, .category {

    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 1.5vh 0 ;

}

.category {
    gap: 2vh;

    width: 60%;
}




.dish > p {
    font-size: 19px;
    text-align: center;
    font-weight: 100;
}

.dish > span {
    font-size: 23px;
    font-weight: 600;
    font-weight: 200;
}

.specialTitle, .categoryTitle {
   
    text-transform: uppercase;
    font-size: 24px !important;
    font-weight: 100;
    text-align: center;
}

.categoryTitle {
    font-size: 30px !important;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  font-size: 16px;
    font-weight: 200;
    margin: 1.5vh 0;
    width: 75%;
    text-align: center;
   
  
}

.item > span {

    font-size: 21px;
    font-weight: 200;

}

.item > p {
    text-align: center;
    width: 80%;
    font-weight: 100;
    font-size: 20px;
}

.ul {
    width: 40%;
    flex-wrap: wrap;
  

    margin: 0;
    padding: 0;
   gap: 0.6rem;
      display: flex; 
    
    justify-content: center;
      margin-bottom: 5vh;
    
}

.tabButton {
  width: auto;
 margin: 0;
 padding: 0;

   
   
}


.tabButton >  button {
    font-size: 17px;
    padding: 1vh 1vw;
    border-radius: 18px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color:  #fdebf0 ;
    text-transform: uppercase;
    color: #444;
    font-weight: 200;
    border: 0.5px #444 solid;
    margin-right: 2vw;
    width: 100%;

}


.seperator {
    width: 150px;
    height: 50px;
    margin: 4vh 0;
}

.activeTabButton {

    padding: 6px;
    border-radius: 20px;
    border-color: #fff;
    color: #fff !important;
    background-color: #2d2c2c !important;
    
}

.hr {
    width: 60%;
    margin: 2.3vh 0;
 
    


}


#specialT {
    color: black;

font-size: 44px !important;
    
    font-weight: 200;
}



@media (max-width: 1000px) {
    .ul {
        width: 70%;
      
    }

    .message {
        width: 60%;
    }

   .item > .message {
        width: 60%;
        
    }

 

}

@media (max-width: 600px) {
 
    .message {
        width: 80%;
        font-size: 14px;
    }

    .message1{

        width: 80%;
        font-size: 17px;

    }

.category {
    width: 90%;
}
 
        .item {
            width: 100%;
        }


        .item > .message {
            width: 80%;
            font-size: 14px;

        }


    .container > .specialP {
        font-size: 23.5px !important;
        color: #444;
        width: 80%;
        text-align: center;
        font-weight: 100;
    }



    #specialT {

        font-size: 23.5px !important;

    }


.dish > p {
    font-size: 16px;
    text-align: center;
}

.dish > span {
    font-size: 17px;
    font-weight: 200;
    width: 90%;
    text-align: center;
}

.item > p {
    font-size: 18px;
    text-align: center;
}

.item> span {
    font-size: 17px;
    font-weight: 200;
    width: 90%;
    text-align: center;
}


.dish {

    width: 80%;

 






}
.category > .categoryTitle {
    font-size: 22px !important;
    width: 80%;

}

.categoryTitle {
    font-size: 22px !important;
    width: 80%;

}

.dish > .specialTitle {
    font-size: 20px;
}

.specialTitle {
    font-size: 20px !important;
}


}