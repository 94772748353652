.container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fdebf0;

    position: relative;
    z-index: 0;
    color: white;
  
  
  
  }
  
  .abso {
  
    position: fixed;
    height: 100%;
    width: 100%;
    background-size: cover;
    opacity: 1;
    z-index: -1;
    top: 0;
    left: 0;
  
  
  
  
  }


  
  
  .wrapper {
    position: relative;
  
    z-index: 1;
    width: 100%;
    height: 100%;
  gap: 2rem;
    display: flex;
    background-color: transparent;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
  
  
  }


  .tabWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
  }
  
  .preloader {
    height: auto;
    width: 100%;
    display: flex;
  
    padding: 20vh 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    font-size: 40px;
    animation: oscillate 1s infinite;
    color: #fff;
    font-weight: 600;
  
  }


  
  @keyframes oscillate {
    0% {
      color: #ddd;
    }
  
    50% {
      color: #999;
    }
  
    100% {
      color: #ddd;
    }
  }


  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    width: 100%;
    padding: 2vh 2vw;
    
    gap: 2rem;
    text-transform: uppercase;
  }

  .tabs > div {
   
    transition: all 0.1s ease-in-out;
    font-size: 25px;
    text-align: center;
  }

  .tabs > div:hover {

    color: gold;
    cursor: pointer

  }
  
  
  
  
  
  .wrapper2 {
    position: relative;
    width: 95%;
    height: 100vh;
    display: flex;
    align-items: center;

    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    z-index: 1;
  
  }
  
  
  
  .topBar {
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
   

    padding: 2vh 0;
    gap: 6rem;
    height: auto;
    width: 100%;
  }
  
  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  
  
  }
  
  .section2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  
  
  }
  
  .section2>p {
    font-size: 22px;
  
    color: #444;
    padding-top: 3vh;
    text-transform: uppercase;
    font-weight: 200;
    cursor: pointer;
  }
  
  .section>h1 {
    color: #222;
    font-size: 60px;
    padding-top: 3vh;
    font-weight: 200;
  
  
  }
  
  .section>img {
    height: 130px;
    width: auto;
  }
  
  
  .profileImg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    cursor: pointer;
  
  }
  
  .profileImg>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  
  .wrapper>h2 {
    font-size: 40px;
    margin-bottom: 4vh;
    margin-top: 3vh;
    color: #fff;
  
  }
  
  
  .wrapper2>h2 {
    font-size: 32px;
    font-weight: 300;
    margin: 2vh 1vw;
    padding: 1vh 0;
    color: #fff;
    gap: 4rem;
    width: 45%;
    text-align: center;
    background-color: #f3c7c7!important;
    font-weight: 200;
  

    color: #222;
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  
  
  
  }
  
  .properties {
  
    width: 100%;
    display: none;
  
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  
    gap: 2vh;
    padding-bottom: 6vh;
  }
  
  .property {
    height: auto;
    background-color: #faf9f6;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    width: 40%;
    border: 1px grey solid;
  
  color: black;
  
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .property>.imgContainer {
  
    height: 150px;
    width: 40%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .here {
    
    color: goldenrod;
    transition: 0.3s all ease-in-out;
    font-weight: 600;
  }
  
  
  .here:hover {
    color: #fad4c3;
    font-weight: 600;
  
  }
  
  .property>.imgContainer>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 150ms all;
  }
  
  .property>.details {
    width: 70%;
    height: 100%;
    display: flex;
  
    flex-direction: column;
    gap: 0.2rem;
  
    align-items: center;
    padding: 0.3vh 1vw;
    justify-content: center;
  
  
  
  
  }
  
  .selectorDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    width: 80%;
  }
  
  .property>.details>.propName {
    font-size: 30px;
    text-align: center;
  
  }
  
  
  
  
  /* list property */
  .listPropertyForm {
  
  
  
    width: 60%;
  
  
  
  
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  
    padding-top: 6vh;
  
  }
  
  .removeIcon {
    position: absolute;
    z-index: 999;
    width: 30px;
    height: 30px;

    top: 0;

    margin-left: 10px;
    margin-top: 12px;
    font-weight: bold;
    align-self: flex-start;
    cursor: pointer;
    background-color: #222;
    border-radius: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

  }


  .removeIcon:hover {
    background-color: #777;
  }
  
  .delWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }

  .delWrapper > h2 {
    text-align: center;
    width: 80%;
  
  }
  
  
  .btnDels {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
  }
  
  .btnDelete,
  .btnEdit,
  .btnProfile {
  
  
  
    width: 40%;
    margin: 0.2vh 0;
    padding: 2px;
    background: transparent;
    border: 2px #222 solid;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
  font-weight: 400;
  
  }
  
  .btnDelete2,
  .btnEdit2 {
    width: 50%;
    margin: 0.2vh 0;
    background: transparent;
    color: #fff;
    border: 2px #fff solid;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: 400;
    
  }
  
  
  
  .btnProfile:hover {
  
    color: rgb(30, 147, 30);
    border: 2px rgb(30, 147, 30) solid;
  
  
  
  
  
  }
  
  
  .btnDelete:hover,
  .btnDelete2:hover {
  
    color: red;
    border: 2px red solid;
  
  
  }
  
  
  
  .btnEdit:hover,
  .btnEdit2:hover {
    color: goldenrod;
    border: 2px goldenrod solid;
  
  
  }
  
  .listPropertyWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  #f3c7c7!important;
    width: 100%;
    height: 100%;
    margin-bottom: 3vh;
    border-radius: 20px;
    padding: 0.5vh 0.2vw;
  
  }
  
  .listPropertyWrapper>h2 {
    text-align: center;
    margin: 0.2rem 0;
    margin-bottom: 4vh;
    margin-top: 3vh;
    font-size: 38px;
    color: #fff;
  }
  
  .listPropertyWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    width: 80%;
  }
  
  .logout {
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .logout:hover {
  
    color: goldenrod;
   
  }
  
  
  
  form>.inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
  
    justify-content: space-between;
  }
  
  
  .inputs>label {
    font-size: 18px;
    width: 100%;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: space-between
  }
  
  
  .inputs>label>input {
    font-size: 17px;
  
  }
  
  
  
  
  
  
  .listPropertyWrapper>form>select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #222;
    outline: none;
    padding: 0.5rem 0.25rem;
    width: 100%;
  }
  
  
  
  
  
  .scripTitle {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 2vh;
    margin-top: 4vh;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
  }
  
  .scripTitle>span {
    font-weight: 400;
    color: green;
    font-size: 20px;
  }
  
  .changeRep {
    font-size: 17px;
  
  }
  
  .options {
    width: 80%;
    font-size: 18px;
  }
  
  
  
  .scrip {
  
    width: 100%;
  
    font-size: 18px;
    height: 350px;
  }
  
  .types {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 18px;
    color: #fff;
    gap: 1rem;
  }
  
  .types2 {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
    width: 80%;
    font-size: 20px;
    justify-content: space-evenly;
    margin-top: 2vh;
  }
  
  .upload {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  .upload2 {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    width: 40%;
    justify-content: center;
    gap: 1vh;
  }
  
  .userImg {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .userImg>img {
    width: 100%;
    height: 100%;
  
    object-fit: cover;
  
  }
  
  .passMessage {
    margin: 2vh 0;
  
    text-align: center;
  }
  
  .currentPass {
  
    font-size: 21px;
  
  }
  
  .greenText {
    color: green;
  }
  
  .type>label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2vh;
  }
  
  
  
  
  .radio {
    padding-top: 2vh;
    display: flex;
    font-size: 17px;
    gap: 2rem;
  }
  
  .radio>label>input {
    margin-right: 0.5vw;
  }
  

  
  
  .keywordDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    margin-bottom: 1vh;
  }
  
  
  .speshInput {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 14px;
    border: 0;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.09);
    width: 180px;
  }
  
  
  .btnUpload2 {
    color: white;
    display: flex;
    cursor: pointer;
    font-weight: 400;
    padding: 0.5vh;
    border: 2px #fff solid;
    text-align: center;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
  }
  
  .btnUpload2:hover {
    color: green;
  
    border: 2px green solid;
  }
  
  
  .photoDel {
    font-weight: 600;
    color: #222;
    background: #9f9f9f;
    cursor: pointer;
  
  }
  
  .photoDel:hover {
  
    background: #fff;
  
  }
  
  
  .keywords {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  
  .keywords2 {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    margin-bottom: 3vh;
  }
  
  
  .keywords2>.keyword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    height: auto;
    font-size: 16px;
    padding: 5px;
    border: 2px #fff solid;
    color: #fff;
  
  }
  
  .keywords2>.keyword>p {
    text-align: center;
  }
  
  .formButton2 {
  
    color: green;
    width: 50%;
    margin: 5vh 0;
    background: transparent;
    border: 2px green solid;
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
  }
  
  
  .formButton2:hover {
    color: goldenrod;
    border: 2px goldenrod solid !important;
  
  
  }
  
  .photoUnit {
    display: flex;
    flex-direction: column;
  }
  
  .map {
    margin-top: 2vh;
    width: 92%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  
    transition: opacity 0.5s ease-in;
  }
  
  
  .claimer {
  
    margin: 2vh 0;
    color: #222;
    font-weight: 200;
    font-size: 18px;
    text-align: center;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  @media only screen and (max-width: 1105px) {
  
  
    .topBar {
      margin-bottom: 0;
  
      gap: 3rem;
  
    }
  


    
  
    .tabs > div {
      transition: all 0.1s ease-in-out;
      font: 17px;
    }
  
  
  
  
  
    .section>h1 {
      color: #fff;
      font-size: 40px;
      padding-top: 3vh;
      text-align: center;
  
  
    }
  
    .section>img {
      height: 100px;
      width: auto;
    }
  
    
  
  
    .profileImg {
    
      height: 100px;
      
  
    }
  
  
  
  
  
  
  
  }
  
  
  @media only screen and (max-width: 900px) {
  
    .listPropertyForm {
  
      padding-top: 0;
  
      width: 80%;
    }
  
    .wrapper>h2 {
      margin: 1vh 0 !important
    }
  
  
    .section2>p {
      font-size: 18px;
  
  
    }
  
  
  
  
  
  
  
  
  
    .properties {
  
      flex-direction: column;
  
  
    }
  
    .property {
  
      width: 90%;
  
    }
  
    .property>.details>.propName {
      font-size: 20px;
  
  
    }
  
  
    .section>h1 {
      display: none;
  
  
    }
  
    .topBar {
  
      gap: 2rem;
  
    }
  
  
  
  
  
  }
  
  
  @media only screen and (max-width: 600px) {
  
    .wrapper2>h2 {
  
      font-size: 25px;
      width: 85%;
      padding-left: 2vw;
  
      padding-right: 2vw;
  
    }

    .tabs {
      gap: 1rem;
    }


    .tabs > div {
      font-size: 17px;




    }

    .section{
     display: none;
    }
  
  
    .garden>input {
      margin-left: 2vw;
    }
  
  
    .modal {
      width: 80%;
    }
  
  
    .btnDelete,
    .btnEdit,
    .btnProfile {
      font-size: 15px;
    }
  
  
  
    .inputs>label {
      flex-direction: column;
  
  
  
    }
  
  
  
  
    .wrapper>h2 {
      font-size: 30px;
    }
  
    .property>.imgContainer {
      height: 110px;
    }
  
    .scrip {
      font-size: 16px;
    }
  
    .property>.details>.propName {
      font-size: 18px;
  
  
    }
  
  
    .inputs>label {
      font-size: 15px;
      text-align: center;
  
    }
  
  
    .inputs>label>input {
      font-size: 16px;
  
    }
  
  
    .listPropertyWrapper>h2 {
      font-size: 30px;
    }
  
    .listPropertyForm {
  
  
  
      width: 90%;
  
    }
  
    .keywordDiv {
      gap: 2rem;
    
        width: 90%;
        flex-direction: column;
      
    }
  
  
  
    .btnUpload2 {
      font-size: 18px;
  
    }
  
    .types {
      gap: 2rem;
    }
  
    .formButton2 {
  
      width: 60%;
      padding: 0 1vw;
  
      font-size: 20px;
  
  
    }
  
    .radio {
      flex-wrap: wrap;
  
      gap: 1rem;
  
      font-size: 16px;
    }
  
  
  
    .profileImg {
  
      height: 80px;
  
      width: 80px;
  
    }
    .section2>p {
      font-size: 22px;
      font-weight: 600;
     
    }
  
  
  
  }
  
  
  @media only screen and (max-width: 300px) {
  
    .listPropertyWrapper>form {
      width: 90%;
    }
  
    .keywordDiv {
      width: 90%;
      flex-direction: column;
    }
  
    .listPropertyWrapper>h2 {
      font-size: 25px;
    }
  
    .wrapper>h2 {
      font-size: 25px;
    }
  
    .topBar {
      margin: 0 1vw;
      gap: 1rem;
    }
  
  
    .section2>p {
      font-size: 22px;
    }
  
    .selectorDiv {
      width: 100%;
    }
  
  
  
  
  }