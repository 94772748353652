.container {
    background-color: #2d2c2c !important;
    height: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vh 0;
    justify-content: center;
}

.container > .specialP {
    color: #fff;
    font-size: 16px;
    font-weight: 200;
    width: 80%;
    text-align: center;
    padding: 2px 0;
   
}



@media only screen and (max-height: 500px) {

    .container > .specialP {
        color: #fff;
        font-size: 14px;
      
        width: 80%;
       
    }


}




@media only screen and (max-width: 600px) {

    .container {

       

    }

    .container > .specialP {
        color: #fff;
        font-size: 11.5px;
        text-align: center;
       
    }

   


}
