.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    visibility: visible;
    opacity: 1;
    background-color: #2d2c2c !important;


}

.imgCont {
    height: 500px;
    width: 500px;
    position: relative;
}

.imgCont > img {
    height: 100%;
    width: 100%;
   


}

.imgCont > .imgAbso {
   
    position:absolute;
    height: 100%;
   top: 3.5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imgAbso > img {
    width: 356px;
    height: 351.5px;

}

@media only screen and (max-width: 500px) {


    .imgCont {
        height: 370px;
        width: 370px;
    }

    .imgAbso > img {
        width: 263.44px;
        height: 260.11px;
    
    }






}

@media only screen and (max-height: 400px) {


    .imgCont {
        height: 270px;
        width: 270px;
    }

    .imgAbso > img {
        width: 192.24px;
        height: 189.81px;
    
    }






}


