/* *********** variables *********** */
:root {
    --white: #ffffff;
    --black: #000000;
  }
  
  /* *********** no carousel styles *********** */
  body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
  

  
  .text-center {
    text-align: center;
  }
  

  
  /* ***********carousel styles*********** */
  /* animation duration */
  :root {
    --animation-duration: 600ms;
  }
  
  /* keyframes */
  /* move slide from left to the viewport */
  @keyframes animate-in-to-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* move slide out of the viewport to the left */
  @keyframes animate-out-to-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* move slide from right to the viewport */
  @keyframes animate-in-to-right {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* move slide out of the viewport to the right */
  @keyframes animate-out-to-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  /* classes to use the keyframes */
  .enter-to-left {
    animation: animate-in-to-left var(--animation-duration);
   
  }
  
  .exit-to-left {
    animation: animate-out-to-left var(--animation-duration);
    display: none !important;
  }
  
  .exit-to-left .carousel-caption {
    display: none !important;
   opacity: 0;
  }
  
  .enter-to-right {
    animation: animate-in-to-right var(--animation-duration);
   
  }
  
  .exit-to-right {
    animation: animate-out-to-right var(--animation-duration);
    display: none !important;
  }
  
  .exit-to-right .carousel-caption {
    display: none !important;
    opacity: 0;
  }


  .exit-to-right  img {
    opacity: 0;
    display: none !important;
  }

  .exit-to-left  img {
    opacity: 0;
    display: none !important;
  }
  
  .carousel-slider-wrapper {
    width: 80%;
    height: 500px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .carousel-slider-wrapper * {
    box-sizing: border-box;
  }
  
  .carousel-slide {
    background-color: #222;
    margin: 0;
    display: none;
    max-width: 100%;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 2rem;
    height: 100%;
    text-align: center;
  }
  
  .carousel-slide.swipe {
    user-select: none;
    pointer-events: none;
    display: none;
  }
  
  .carousel-slide.active-slide {
    display: flex;
    background-color: transparent;
    width: 100%;
  }
  
  .carousel-slide.active-slide.next-active-slide {
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .carousel-slide.active-slide img {
    width: 100%;
    height: 100%;
    opacity: 0.15;
    object-fit: cover;
    
  }

  .carousel-slide img {
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    
  }


      
 

  .carousel-slide > .carousel-caption {
    display: none; 
  }
    



  
  .carousel-slide.active-slide > .carousel-caption {
    position: absolute;
    display: flex;
    flex-direction: column;
   
   
    text-align: center;
    align-items: center;
    justify-content: center;
   
  
   
    width: 100%;
  
   
   
    transition: all 0ms ease-in;
    z-index: 10;
   
   

   background-color: transparent;

    padding-bottom: 5px;
    color: #fff;
  }
  

  
  .carousel-slide.active-slide .carousel-caption .carousel-caption-title {
    margin: 0 0 5px;
   width: 50%;
    font-weight: 100;
    font-size: 35px;
    color: #fff;
    text-align: center;
  }
  
  .carousel-slide.active-slide .carousel-caption .carousel-caption-subtitle {
    margin: 0;
    width: 50%;
    font-weight: 200;
    font-size: 26px;
    color: #fff;
    text-align: center;
  }

  @media (max-width: 1402px) {
    .carousel-slider-wrapper {
      width: 70%;
     
    }




  }


  @media (max-width: 838px) {


    .carousel-slider-wrapper {
      height: 350px;
      width: 100%;
     
    }

      
  .carousel-slide.active-slide > .carousel-caption {
   
    top: 90px;
  
  }

  
    
   

      .carousel-slide.active-slide .carousel-caption .carousel-caption-title {
        text-align: center;
        margin: 0 0 5px;
        font-size: 26px;
      }
      
      .carousel-slide.active-slide .carousel-caption .carousel-caption-subtitle {
        text-align: center;
        margin: 0;
        font-size: 20px;
      }
  }

  @media (max-width: 438px) {
    .carousel-slide.active-slide > .carousel-caption {
       
      }

      .carousel-slide.active-slide .carousel-caption .carousel-caption-title {
        margin: 0 0 5px;
        font-size: 19px;
      }
      
      .carousel-slide.active-slide .carousel-caption .carousel-caption-subtitle {
        margin: 0;
        font-size: 13px;
      }
  }


  @media (max-height: 438px) {
 

      .carousel-slide.active-slide .carousel-caption .carousel-caption-title {
        margin: 0 0 5px;
        font-size: 21px;
      }
      
      .carousel-slide.active-slide .carousel-caption .carousel-caption-subtitle {
        margin: 0;
        font-size: 13px;
      }
  }

  
@media only screen and (min-width: 1720px) {


  .carousel-slider-wrapper {
    width: 800px;
    height: 550px;
    
    display: flex;
    position: relative;
    overflow: hidden;
  }


    
  


}




  
  .carousel-control {
    position: absolute;
    top: 50%;
    left: 0;
    width: 65px;
    font-size: 20px;
    color: var(--white);
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: #fff;
    filter: alpha(opacity=50);
    z-index: 1;
  }
  
  .carousel-control .left-triangle {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-left: 35px solid var(--white);
    border-bottom: 30px solid transparent;
    position: absolute;
    left: -2px;
    cursor: initial;
    transform: translateY(-50%);
  }
  
  .carousel-control .right-triangle {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-right: 35px solid var(--white);
    border-bottom: 30px solid transparent;
    position: absolute;
    right: -2px;
    cursor: initial;
    transform: translateY(-50%);
  }
  
  .carousel-control.carousel-left-arrow button {
    position: absolute;
    left: 1px;
    transform: translateY(-50%);
  }
  
  .carousel-control.carousel-right-arrow {
    right: 0;
    left: auto;
    color: #fff;
    background-color: #fff;
  }
  
  .carousel-control.carousel-right-arrow button {
    position: absolute;
    right: 1px;
    transform: translateY(-50%);
    
  }
  
  .carousel-control button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    height: 20px;
  }
  
  .carousel-control button.padding-left-15 {
    padding-left: 15px;
  }
  
  .carousel-control button.padding-right-15 {
    padding-right: 15px;
  }
  
  .carousel-control button svg {
    width: 15px;
    opacity: 0.5;
    cursor: pointer;
  }
  
  .carousel-control button svg:hover {
    opacity: 0.9;
  }
  
  .carousel-control button i {
    opacity: 0.5;
    cursor: pointer;
    color: var(--white);
    font-size: 28px;
  }
  
  .carousel-control button i:hover {
    opacity: 1;
  }
  
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    z-index: 15;
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .carousel-indicators.position-left {
    left: 35px;
  }
  
  .carousel-indicators.position-right {
    right: 35px;
  }
  
  .carousel-indicators.position-center {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carousel-indicators li {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--indicatorsColor);
    border-radius: 10px;
    opacity: 1;
  }
  
  .carousel-indicators li:hover {
    background-color: var(--indicatorsColor);
    opacity: 0.7;
  }
  
  .carousel-indicators li.active {
    width: 10px;
    height: 10px;
    margin: 0;
    background-color: var(--indicatorsColor);
    cursor: initial;
    opacity: 1;
  }
  
  @media (min-width: 768px) {
    .carousel-slider-wrapper .carousel-slide.active-slide .carousel-caption {
      padding-bottom: 30px;
     
    }
  
    .carousel-slider-wrapper .carousel-control .left-triangle {
      border-top: 50px solid transparent;
      border-left: 65px solid var(--white);
      border-bottom: 50px solid transparent;
    }
  
    .carousel-slider-wrapper .carousel-control .right-triangle {
      border-top: 50px solid transparent;
      border-right: 65px solid var(--white);
      border-bottom: 50px solid transparent;
    }
  
    .carousel-slider-wrapper .carousel-control button {
      height: 50px;
    }
  
    .carousel-slider-wrapper .carousel-control button svg {
      width: 25px;
    }
  
    .carousel-slider-wrapper .carousel-control button i {
      font-size: 50px;
    }
  
    .carousel-slider-wrapper .carousel-indicators.position-left {
      left: 65px;
    }
  
    .carousel-slider-wrapper .carousel-indicators.position-right {
      right: 65px;
    }
  
    .carousel-slider-wrapper .carousel-indicators li {
      width: 10px;
      height: 10px;
    }
  
    .carousel-slider-wrapper .carousel-indicators li.active {
      width: 12px;
      height: 12px;
    }
  }
  
  @media (min-width: 992px) {
    .carousel-slider-wrapper .carousel-slide.exit-to-left,
    .carousel-slider-wrapper .carousel-slide.exit-to-right .carousel-caption {
      opacity: 1;
    }
  }
  