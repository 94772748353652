
.carouselCont {
  
    height: 90vh;
    width: 100%;
   
background-color: #222;
position: relative;
z-index: -99;

  
}

.img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    object-fit: cover;
}

.homeAbso {
    position: absolute;
    top: 40%;
    width:  100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeSpecialP {
    font-size: 70px;
    text-align: center;
    color: #fff;
    width: 80%;
}

.absoClass {
position: absolute;
height: 100%;
width: 100%;
z-index: 1;
opacity: 0.6;
display: flex;


}
.absoClass > img {
    width: 50%;
}

.absoClass2 {

    width: 520px;
    height: 550px;
   
   

    }

.coverSpace {
    height: 12vh;
}



@media only screen and (max-width: 850px) {

    .homeAbso > p {
        font-size: 36px;
     
        color: #fff;
    }

    
.carouselCont {

    height: 50vh;

}


  

}




@media only screen and (max-width: 780px) {

    .homeAbso {
     
        top: 40%;

    }
   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }


        .homeAbso > p {
            font-size: 33px;
         
            color: #fff;
        }


}



@media only screen and (max-width: 660px) {

   




        .homeAbso > p {
            font-size: 30px;
         
            color: #fff;
        }


}



@media screen and (min-width: 1022px) and (max-height: 822px) {


    .homeAbso {
       
        top: 35%;
       
    }
    
    .homeAbso > p {
        font-size: 55px ;
        
        
        width: 90%;
    }



   
    
    
}