
.carouselCont {
  
    width: 100%;
    height: 90vh;
   position: relative;
    background-color: black;
  
}

.carouselCont > img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    object-fit: cover;

}

.homeAbso {
    position: absolute;
    top: 35%;
     width:  100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     z-index: 8;
   
}

.homeSpecialP {
    text-align: center;
    font-size: 80px;
  
    color: #fff;
    width: 100%;
}


.absoClass {
position: absolute;
height: 100%;
width: 100%;
z-index: 1;
opacity: 0.6;
display: flex;


}
.absoClass > img {
    width: 50%;
}

.absoClass2 {

    width: 520px;
    height: 550px;
   

 

    }

.coverSpace {
    height: 12vh;
}


@media only screen and (max-width: 850px) {

    .homeSpecialP {
        font-size: 40px;
     
        color: #fff;
    }

    
.carouselCont {

    height: 50vh;

}


  

}


@media only screen and (max-width: 760px) {

   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }


        .homeSpecialP {
            font-size: 38px;
         
            color: #fff;
        }


}


@media only screen and (max-width: 660px) {

   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }


        .homeSpecialP {
            font-size: 31px;
         
            color: #fff;
        }


}



@media screen and (min-width: 1022px) and (max-height: 822px) {


    .absoClass2{
       
        top: 35%;
       
    }
    
    .homeSpecialP {
        font-size: 55px ;
        
        
        width: 90%;
    }



   
    
    
}