.container{


    position: fixed;
    height: auto;
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 998;
    
    bottom: 0;

}

.wrapper {
    position: sticky;
    background-color: #f3c7c7 !important;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid white;
    transition: transform 0.2s ease-in-out;
    
    
}

.btn {
    height: 7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 2px #fff solid;
    border-left: 2px #fff solid;
    border-right: 2px #fff solid;
   

}

.btn > a, .btn > div{
    text-decoration: none;
    display: flex;
   
    align-items: center;
    text-align: left;
    gap: 1rem;
    color: #444;
    font-weight: 300;
    font-size: 18px;

}





@media only screen and (max-width: 650px) {

    .container {
        display: flex;
    }


}




