.container {
    width: 100%;
    height: auto;
    padding: 1vh 1vw;
   
    background-color:    #fdebf0 !important;

    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 2vh 0;
}


.carouselCont {
  
    width: 100%;
    height: 90vh;
   position: relative;
    background-color: #222;

   

  
}

.carouselCont > img {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    object-fit: cover;
}

.homeAbso {
    position: absolute;
    top: 40%;
     width:  100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     z-index: 8;
}

.homeSpecialP {
    text-align: center;
    font-size: 70px;
  
    color: #fff;
    width: 100%;
}











.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    padding-bottom: 2vh;
  
  
}



.topWrap {
    height: auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 1.5rem;
    padding: 3vh 0;
}

.topWrap > .specialP{

    font-size: 38px !important;

    color: #444 !important;
    font-weight: 200;

}

.topWrap > p {
    font-size: 20px;
    color: #444;
    font-weight: 200;
}



.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
    margin-bottom: 1vh;
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.6s ease-in-out;
}

.profile.appear {
    opacity: 1;
    transform: translateY(0);
    
    
  }

.imgWrapper {
width: 100%;
height: 400px;
}

.imgWrapper > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.textWrapper {
    padding: 6px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 0.5rem;
    background-color: #fff7f9;
    padding: 1.4vw;

   
}

.textWrapper > .specialP {
    font-size: 32px !important;
    width: 100%;
    text-align: left;
    color: #444;
    font-weight: 200;

}


.textWrapper > h5 {
    font-size: 20px;
    width: 80%;
    text-align: left;
    color: #444;
    font-weight: 200;
}

.textWrapper > p {
    font-size: 17px;
    width: 90%;
    text-align: left;
    color: #444;
    font-weight: 200;
    
}

.hr {
    width: 40%;
 
    
}

.hr  > hr{
    align-self: left;
    margin-bottom: 2vh;

}


.extraDiv {
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

}

.extraDiv > .specialP {
    text-align: center;
    font-size: 20px;
    width: 70%;
    color: #444;
    font-weight: 200;
}

.extraDiv> a  {
    text-decoration: none;
}



.extraDiv> a > button {
    background-color: #2d2c2c !important;
    
   text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 60px;
    display: flex;
    font-size: 18px;
    
    cursor: pointer;
    font-weight: 200;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #fff!important;
}


.extraDiv> a > button:hover  {
    background-color: #fff !important;
    color: #444 !important;

   
   

}


@media only screen and (max-height: 700px) {

    .homeAbso > p {
        font-size: 35px;
     
        color: #fff;
    }

    
    .homeAbso {
     
        top: 30%;

    }

}

@media only screen and (max-width: 760px) {

   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }


        .homeAbso > p {
            font-size: 35px;
         
            color: #fff;
        }


}

@media only screen and (max-width: 700px) {

  

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }


        .homeSpecialP {
            font-size: 38px;
         
            color: #fff;
        }


}


@media (max-width: 900px) {

    .wrapper {
        flex-direction: column;


    }

    .profile {

        width: 70%;


    }

    
.imgWrapper {
 
    height: 500px;
    }
   

  

}

@media (max-width: 600px) {
 
    .topWrap > .specialP{

        font-size: 28px !important;
    
        color: #444 !important;
    
    }
    
    .topWrap > p {
        font-size: 16px;
        color: #444;
    }



    .profile {

        width: 90%;


    }
   

        .textWrapper > .specialP {
            font-size: 25px !important;
          
        
        }
        
        
        .textWrapper > h5 {
            font-size: 20px;
          
        }

        .imgWrapper {
       
            height: 300px;
            }
        
        .textWrapper > p {
            font-size: 15px;
            
            
        }





}

