.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
   
    background-position: center;
    background-color: rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-blend-mode: darken;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    
    border-radius: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-bottom: 2vh;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
}

.wrapper>h2 {
    color: #222;
    text-align: center;
    margin: 2.5rem 0;
    font-size: 36px;
}

.wrapper > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 42px;
    width: 80%;
}

.wrapper > form>input {
    width: 50%;
    outline: none;
    border: none;
    border-bottom: 1px solid #333;
}

.wrapper > form>label {
    
    
    
    width: 70%;
    cursor: pointer;
    transition: 150ms all;
    display: flex;
    align-items: center;
    gap: 12px;
}

.wrapper>form>label:hover {
    color: #444;
}



.wrapper>form>input {
   
    font-size: 17px;
   
   
}

.wrapper >form>button {
    outline: none;
    background-color: #222;
    color: #fff;
  
    padding: 0.5rem 1.25rem;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid transparent;
}

.wrapper >form>button:hover {
    background-color: #444;
    border-color: #222;
  color: #fff;
}



.wrapper>form>p {
    text-align: center;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper>form>p>a {
    text-decoration: none;
    font-size: 17px;
    color: #888;
    margin-top: 0.25rem;
}

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mobileView {
    display: none;
}
/* RESPONSIVE */

@media only screen and (max-width: 1092px) {
    .wrapper {
        width: 60%;
    }

    
.wrapper > form>input {
    width: 80%;
    outline: none;
    border: none;
    font-size: 15px !important;
    border-bottom: 1px solid #333;
}

}




@media only screen and (max-width: 792px) {
    .wrapper {
        width: 80%;
    }

    
.wrapper > form>input {
    width: 80%;
    outline: none;
    border: none;
    font-size: 15px !important;
    border-bottom: 1px solid #333;
}

}

