.container {
    height: auto;
    padding: 7vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff7f9;


    color: #333;
   

}

.wrapper {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.6s ease-in-out;
}

.wrapper.appear {
    opacity: 1;
    transform: translateY(0);
    
    
  }

.column {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: left;
    font-size: 18px;
    gap: 1rem;
}

.column > hr {
    width: 70%;
}


.hr {
    width: 50%;
 
    
}

.hr  > hr{
    align-self: left;
    margin-bottom: 1.6vh;

}
.column > .specialP {
    text-align: left;
    color: #444;
    font-size: 21px;
    font-weight: 200;
    
}

.column >  .pDiv {

   
    display: flex;
    align-items: left;
    flex-direction: column;
   justify-content: center;
   font-weight: 200;
 
}


.column >  .pDiv2 {
    margin-bottom: 1vh;
    font-weight: 600;
    

}

.column >  .pDiv > a {

    text-decoration: none;
    color: #444;
    font-weight: 200;


}

.column >  .pDiv > a:hover{
    color: #777;


}

.email {
    font-size: 18px;
    width: 80%;
    font-weight: 600;
    overflow-wrap: break-word;
    
}

.specialSpan {
    font-weight: bold;
    font-weight: 600;
    
}

.pDiv > span {
    text-align: left;
    font-weight: 200;

}

.pDiv > a {
    text-transform: capitalize;
}

.column > a >  button {
    background-color: #2d2c2c !important;
    
    text-decoration: none;
     padding: 0.4vh 0.4vw;
     height: 7.3vh;
     display: flex;
     font-size: 16px;
     width: 130px;
     font-weight: 200;
         cursor: pointer;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     transition: 0.2s all ease-in-out;
     color: #fff!important;
  }




  


 

  .column  > a {
   
    font-size: 18px;
    text-align: center;
    transition: 0.2s all ease-in-out;
    text-decoration: none;
    font-weight: 200;

  }

  


  .column > a > button:hover {
    background-color: #fff !important;
    color: #222 !important;
      

   
 
  }


  @media only screen and (max-width: 900px) {


    .column > a >  button  {

        font-size: 13px;

        width: 80px;
        height: 50px;


    }
    .wrapper {
        flex-wrap: wrap;
        gap: 1rem;
    }





  }


  @media only screen and (max-width: 600px) {

    .wrapper {
        flex-wrap: wrap;
        gap: 2rem;
        width: 75%;
        flex-direction: column;
        justify-content: center;
    }
    



    
.column {
    width: 100%;
}

.column  > a {
   
    font-size: 14px;


}

.column > .specialP {
    text-align: left;
    color: #444;
    font-size: 16px;
    font-weight: 200;
}

.column > hr {
    width: 80%;
}



.email {
    font-size: 12px;
    font-weight: 600;
}

.column > a >  button {
    font-size: 12.5px;
    width: 80px;
    


}
  }



