.carouselCont {

    height: 90vh;
    width: 100%;
    position: relative;
    z-index: -1;





}


.preloaderContainer {

    height: 90vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;






}


.homeSpecialP {
    text-transform: uppercase;
}




.homeAbso {
    position: absolute;
    top: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeAbso>p {
    font-size: 75px;
    text-align: center;
    color: #fff;
    width: 80%;
}

.absoClass {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0.6;
    display: flex;


}

.absoClass>img {
    width: 50%;
}

.absoClass2 {

    width: 520px;
    height: 550px;



}

.coverSpace {
    height: 12vh;
}




@media only screen and (max-width: 1022px) {

    .homeAbso>p {
        font-size: 34px;

        color: #fff;
    }


    .carouselCont {

        height: 50vh;

    }

    .preloaderContainer {
        height: 50vh;


    }




}

@media only screen and (max-width: 730px) {

    .homeAbso>p {
        font-size: 33px;

        color: #fff;
    }


    .homeAbso {

        top: 35%;

    }

}


@media only screen and (max-width: 660px) {



    .absoClass2 {

        width: 220px;
        height: 250px;



    }


    .homeAbso>p {
        font-size: 30px;

        color: #fff;
    }


}


@media screen and (min-width: 1022px) and (max-height: 822px) {


    .homeAbso {

        top: 35%;

    }

    .homeAbso>p {
       


        width: 90%;
    }






}



.mediaContainer {
    width: 100%;
    height: 100%;

}



.mediaContainer > img {
    width: 100%;

    height: 100%;

    object-fit: cover;
}



@media only screen and (max-width: 850px) {




    .mediaContainer {

        height: 50vh !important;

    }


}