* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
 
}


#seperator {
  color: rgb(218, 126, 56);
  margin: 4vh 0;
}

hr {
  border-color: rgb(218, 126, 56);
}



 .rdtPicker > .rdtTime > table > tbody > tr > td > .rdtCounters {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.rdtPicker > .rdtDays {
  width: 100%;
}

.rdtPicker > .rdtDays > table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin: 2vh 0;
  font-size: 22px;
  width: 300px;

}





.rdtPicker > .rdtDays > table > tfoot {
 color: gold;
 font-weight: 600;
 cursor: pointer;
 gap:2rem;
 text-transform: uppercase;
 
}


.rdtPicker > .rdtTime > table {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  margin: 2vh 0;

}


.rdtPicker > .rdtTime > table > thead > tr > .rdtSwitch {
  color: gold;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  

}

.rdtDay{
  
  width: 37px !important;
  font-size: 20px;
  cursor: pointer;
  
}

.dow {
  width: 37px !important;
  font-size: 20px;
  
}

.rdtBtn {
  cursor: pointer;
}

.rdtCounter {
  margin: 3px !important;
}


@media only screen and (max-width: 402px) {

  .rdtDay{
  
    width: 32px !important;
    font-size: 19px;

    
  }

  .dow {
    width: 32px !important;
    font-size: 19px;
  
  }

  .rdtPicker > .rdtDays > table {

    width: 280px;
  
  }


}



