.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5vh 0;
     gap: 1.5rem;

}



.toggleP {
    color: #444;    
    
}

.exteriorWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 4vh;
}


.removeIcon {
    position: absolute;
    z-index: 999;
    width: 25px;
    height: 25px;

    top: 0;

   left: 13px;
    margin-top: 12px;
    font-weight: bold;
    align-self: flex-start;
    cursor: pointer;
    background-color: #222;
    border-radius: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

  }

  .removeIcon:hover {
    background-color: #777;
  }


  .uniButton {

    font-size: 18px;
    padding: 6px;
    margin: 2vh 0;
    background-color: #222;
    color: white;
text-transform: uppercase;
font-weight: 200;
border-radius: 20px;


  }


   .uniButton:hover {
    cursor: pointer;
    background-color: #777;
  }


  .plusButtonDiv {
    width: 70px;
    height: 70px;
    padding: 2vh 0;
}


.plusButton {

    color: rgb(73, 211, 73);

    width: 100%;
    height: 100%;
}

.plusButton:hover {
    color: green;
    cursor: pointer;



}


.newUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 1vh;
    font-size: 20px;
  }

  .newUpload > label{
    text-align: center;
    color: #fff;
  }

  .newUpload > img {
    object-fit: contain;
    width: 100px !important;
    height: 100px !important;
  }


  .uniButton2 {

    font-size: 20px;
    padding: 6px;
    margin: 2vh 0;
    background-color: #fff;
    color: black;
text-transform: uppercase;


  }


  .uniButton2:hover {
    cursor: pointer;
    background-color: #777;
  }


.makeWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3c7c7 !important;
    min-width: 70%;
    width: auto;
    gap: 2rem;
    max-width: 85%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 20px;
    padding: 2vh 2vw;
    transition: all 0.4s ease-in-out;
}





.makeWrapper>h2 {
    font-weight: 200;
    font-size: 30px;
    padding-bottom: 3vh;
    text-align: center;
}


.titleInput {
    margin: 2vh 0;
    font-size: 25px;
    padding: 1vh 1vw;
    max-width: 90%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    height: 50px;
    max-width: 95%;
    min-width: 90%;
}


.titleInput2 {
    margin: 2vh 0;
    font-size: 18px;
    padding: 1vh 1vw;
    margin-bottom: 3vh;
    overflow: hidden;
    word-wrap: break-word;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height: 50px;
    
    max-width: 90%;
  

    z-index: 999;
}



.titleInput3{
    margin: 2vh 0;
    font-size: 18px;
    padding: 1vh 1vw;
    margin-bottom: 3vh;
    overflow: hidden;
    word-wrap: break-word;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height: 50px;
    
    max-width: 90%;
  

    z-index: 999;
}

.titleInput4 {
    margin: 2vh 0;
    font-size: 17px;
    padding: 3vh;
    margin-bottom: 3vh;
    max-width: 80%;
  
    min-height: 150px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
  }

.typeSelection {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2;
    width: 90%;
}

.open {
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 200px;
    word-wrap: break-word;
    margin: 0.5vh 0;
    margin-top: 3vh;
    z-index: 999;
}

.open:hover {
    color: goldenrod;
    cursor: pointer;
}

.open > span {
    font-weight: 600;
}

.hiddenBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:1rem;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}


.formButton2 {
  
    
    font-size: 24px;
    padding: 8px;
    margin: 3vh 0;
    background-color: rgb(62, 193, 62);
   
    color: #222;
  text-transform: uppercase;
  border-radius: 25px;
  font-weight: 400;
  z-index: 999;
  }
  
  
  .formButton2:hover {
  
    cursor: pointer;
    
    background-color: green;
   
  
  }
.typeSelection > label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    gap: 0.2vh;
    margin: 1vh 0;
}

.keywordDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:#c4c0c0  !important;

  min-width: 60%;
  width: auto;
  height: 100%;
  max-width:80%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 20px;
  padding: 2vh 0.7vw;
  transition: all 0.4s ease-in-out;
  gap: 1vh;

}


.contentInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 90%;
   
    gap: 1vh;
    padding: 1vh 1vh;
}


.keywords2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    min-width: 60%;
    max-width: 85%;
    padding: 4vh 0;
    gap: 1vh;
}

.keyword {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eadbdb !important;
    width: auto;
   max-width: 90%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 20px;
    padding: 2vh 0.7vw;
    transition: all 0.4s ease-in-out;
    gap: 1vh;

}





.container > h2 {
    font-weight: 200;
    font-size: 35px;
    color:#222;

}

.menus {
    width: 95%;
    height: auto;
    padding: 2vh;
    display: flex;
    align-items: center;
    gap: 2vh;
    justify-content: center;
    flex-wrap: wrap;

}

.liveMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3c7c7;
    width: 90%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 20px;
    padding: 2vh 0.7vw;
    transition: all 0.4s ease-in-out;
    gap: 1vh;


}

.liveMenu > .topDiv  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}



.liveMenu > .topDiv > h3 {
    font-size: 27px;
    color: #222;
    font-weight: 200;
    width: 80%;
    text-align: center;
}



.liveMenu > .topDiv > .topDivArrow {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: auto;

}


.liveMenu > .topDiv > .topDivArrow > .arrowIcon {
    width: 30px;
    height: 30px;
    color: #888;
    cursor: pointer;
}


.toggleButtonEnter {


    font-size: 18px;
    padding: 6px;
    margin: 2vh 0;
    background-color: goldenrod;
    color: #222;
text-transform: uppercase;
font-weight: 200;
border-radius: 20px;
    

}


.toggleButtonExit {

    font-size: 18px;
    padding: 6px;
    margin: 2vh 0;
    background-color: tomato;
    color: #222;
text-transform: uppercase;
font-weight: 200;
border-radius: 20px;

}


.toggleButtonSave {

    font-size: 18px;
    padding: 6px;
    margin: 2vh 0;
    background-color: green;
    color: white;
text-transform: uppercase;
font-weight: 200;
border-radius: 20px;

}



.toggleButtonEnter:hover {
    cursor: pointer;
    background-color:     rgb(216, 195, 140);
    
  }
  .toggleButtonExit:hover {
   cursor: pointer;
   background-color:  rgb(234, 148, 133);
 }
 .toggleButtonSave:hover {
  cursor: pointer;
  background-color: rgb(113, 205, 113);
}


.liveMenu > .topDiv > .topDivArrow > .arrowIcon:hover {
    color: #fff;

}

.btnDiv  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.btnDels {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


/* MENU STYLES */


.menuContainer {
    padding-top: 6vh;
    padding-bottom: 6vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
    justify-content: space-evenly;
    background-color:   #fef1f1 !important;;
    gap: 1rem;
}

.menuContainer > .specialP {
    font-size: 34px !important;
    color: #444;
    width: 80%;
    text-align: center;
    font-weight: 100;
}

.menuContainer > h5 {
    font-size: 34px;
    font-weight: 200;
}

.specialP {
    font-size: 21px;
    font-weight: 100;
    margin: 1vh 0;
  
  
    
}

.message {
    font-size: 16px;
    font-weight: 300 !important;

    color: #777;
    margin: 1vh 0;
   font-style: italic;
    width: 40%;
    text-align: center;
}




.dish, .category {

    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 1.5vh 0 ;

}

.category {
    gap: 2vh;

    width: 60%;
}




.dish > p {
    font-size: 19px;
    text-align: center;
    font-weight: 100;
}

.dish > span {
    font-size: 23px;
    font-weight: 600;
    font-weight: 200;
}

.specialTitle, .categoryTitle {
   
    text-transform: uppercase;
    font-size: 24px !important;
    font-weight: 100;
    text-align: center;
}

.categoryTitle {
    font-size: 30px !important;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  font-size: 16px;
    font-weight: 200;
    margin: 1.5vh 0;
    width: 75%;
    text-align: center;
   
  
}

.item > span {

    font-size: 21px;
    font-weight: 200;

}

.item > p {
    text-align: center;
    width: 80%;
    font-weight: 100;
    font-size: 20px;
}

.ul {
    width: 40%;
    flex-wrap: wrap;
  

    margin: 0;
    padding: 0;
   gap: 0.6rem;
      display: flex; 
    
    justify-content: center;
      margin-bottom: 5vh;
    
}

.tabButton {
  width: auto;
 margin: 0;
 padding: 0;

   
   
}


.tabButton >  button {
    font-size: 17px;
    padding: 1vh 1vw;
    border-radius: 18px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color:  #fdebf0 ;
    text-transform: uppercase;
    color: #444;
    font-weight: 200;
    border: 0.5px #444 solid;
    margin-right: 2vw;
    width: 100%;

}


.seperator {
    width: 150px;
    height: 50px;
    margin: 4vh 0;
}

.activeTabButton {

    padding: 6px;
    border-radius: 20px;
    border-color: #fff;
    color: #fff !important;
    background-color: #2d2c2c !important;
    
}

.hr {
    width: 60%;
    margin: 2.3vh 0;
 
    


}


#specialT {
    color: black;

font-size: 44px !important;
    
    font-weight: 200;
}



@media (max-width: 1000px) {
    .ul {
        width: 70%;
      
    }

    .message {
        width: 60%;
    }

   .item > .message {
        width: 60%;
        
    }

 

}

@media (max-width: 600px) {



    .titleInput2 {
        max-width: 60% !important;
    }

    .titleInput4 {
        max-width: 60% !important;
    }

    .titleInput3 {
        max-width: 82% !important;
    }
 
    .message {
        width: 80%;
        font-size: 14px;
    }

    .message1{

        width: 80%;
        font-size: 17px;

    }

.category {
    width: 90%;
}
 
        .item {
            width: 100%;
        }


        .item > .message {
            width: 80%;
            font-size: 14px;

        }


    .container > .specialP {
        font-size: 23.5px !important;
        color: #444;
        width: 80%;
        text-align: center;
        font-weight: 100;
    }



    #specialT {

        font-size: 23.5px !important;

    }


.dish > p {
    font-size: 16px;
    text-align: center;
}

.dish > span {
    font-size: 17px;
    font-weight: 200;
    width: 90%;
    text-align: center;
}

.item > p {
    font-size: 18px;
    text-align: center;
}

.item> span {
    font-size: 17px;
    font-weight: 200;
    width: 90%;
    text-align: center;
}


.dish {

    width: 80%;

 






}
.category > .categoryTitle {
    font-size: 22px !important;
    width: 80%;

}

.categoryTitle {
    font-size: 22px !important;
    width: 80%;

}

.dish > .specialTitle {
    font-size: 20px;
}

.specialTitle {
    font-size: 20px !important;
}


}




